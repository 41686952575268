import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import {useHistory, useLocation} from "react-router-dom";
import AppBar from "../../components/Header";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {UserContext} from "../../contexts/user-profile";
import ExportButton from "./ExportButton";
import Button from "@material-ui/core/Button";
import {dateAndTimeRenderer} from "../Bookings/utils/";

const useStyles = makeStyles({
    paper: {
        width: "100%",
        marginTop: 25,
    },
    container: {
        maxHeight: 800,
    },
    createDocument: {
        maxWidth: 200,
        marginLeft: 20,
    },
    textField: {
        margin: "0px 10px",
        minWidth: 200,
    },
    formControl: {
        margin: "0px 10px",
        minWidth: 150,
        maxWidth: 150,
    },
    statusActive: {
        color: 'darkgreen'
    },
    statusBooked: {
        color: 'darkblue'
    },
    additionalFields: {
        color: 'darkblue'
    },
    tableCell: {
        verticalAlign: 'top'
    },
    actionContent: {
        width: '100%',
        textAlign: 'center'
    },
    tableRow: {
        cursor: 'pointer'
    },
    searchField: {
        width: "100%"
    },
    sortIcon: {
        lineHeight: '20px'
    },
    columnHeader: {
        cursor: 'pointer'
    },
    newUserHeader: {
        fontSize: '18px',
        padding: '10px 10px'
    },
    newUserText: {
        padding: '5px 10px'
    },
    link: {
        color: '#0a8bcc'
    },
    exportButtonContainer: {
        width: '100%',
        textAlign: 'right',
    },
    exportButton: {
        marginTop: '5px',
        height: '40px'
    },
    clearFilterButtonContainer: {
        width: '100%',
        textAlign: 'left',
    },
    clearFilterButton: {
        width: '70px'
    }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            maxWidth: 250,
        },
    },
    variant: "menu",
    getContentAnchorEl: null,
};

const getFileName = (response) => {
    let filename = "";
    const disposition = response.headers["content-disposition"];
    if (disposition) {
        const prefix = "attachment; filename=";
        const index = disposition.indexOf(prefix);
        if (index !== -1) {
            filename = disposition.substring(index + prefix.length);
        }
    }
    return filename;
};

export default function BookingsPage() {

    const bookingDateRenderer = (date) => {
        if (date) {
            return dateAndTimeRenderer(date);
        }
    }

    const detailsRenderer = (data) => {
        if (data instanceof Object) {
            return data["post_code"];
        }
    }

    const statusRenderer = (value) => {
        if (value === 'Booked') {
            return <span className={classes.statusBooked}>Booked</span>;
        } else if (value === 'Active') {
            return <span className={classes.statusActive}>Active</span>;
        }
        return value;
    };

    const history = useHistory();
    const classes = useStyles();

    const {isAdmin, name, organizationId} = useContext(UserContext);

    const [organizations, setOrganizations] = useState([]);
    const [organizationValue, setOrganizationValue] = useState("All");

    const columns = [];

    if (isAdmin) {
        columns.push({
            id: "organization_name",
            sortable: true,
            key: "column_organization_name",
            label: "Organisation"
        });
    }

    columns.push(
        {
            id: "booking_date",
            sortable: true,
            key: "column_booking_date",
            label: "Ref Date",
            format: bookingDateRenderer
        },
        {
            id: "status",
            sortable: true,
            key: "column_status",
            label: "Status",
            align: "center",
            format: statusRenderer
        },
        {
            id: "type",
            sortable: true,
            key: "column_type",
            label: "Type",
        },
        {
            id: "booking_id",
            sortable: true,
            key: "column_booking_id",
            label: "Our ID"
        },
        {
            id: "client_ref",
            sortable: true,
            key: "column_client_ref",
            label: "Client Ref",
        },
        {
            id: "vehicle_model",
            sortable: true,
            key: "column_vehicle_model",
            label: "Vehicle Model",
        },
        {
            id: "vehicle_registration",
            sortable: true,
            key: "column_vehicle_registration",
            label: "Vehicle Reg.",
        },
        {
            id: "collection",
            key: "column_collection_details",
            label: "Collection",
            format: detailsRenderer
        },
        {
            id: "delivery",
            key: "column_delivery_details",
            label: "Delivery",
            format: detailsRenderer
        },
        {
            id: "return",
            key: "column_return_details",
            label: "Return",
            format: detailsRenderer
        },
        {
            id: "tier",
            sortable: true,
            key: "column_tier",
            label: "Tier",
            align: "center"
        }
    );


    const location = useLocation();
    const [locationParsed, setLocationParsed] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rowsPerPageOptions] = useState([5, 10, 20, 30, 50, 100]);
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    const [sortColumn, setSortColumn] = useState("booking_date");
    const [sortDirection, setSortDirection] = useState("desc");

    const [statuses, setStatuses] = useState([]);
    const [bookingId, setBookingId] = useState("");
    const [statusValue, setStatusValue] = useState("All");
    const [dateValue, setDateValue] = useState("All");
    const [selectedDateFrom, setSelectedDateFrom] = useState(null);
    const [selectedDateTo, setSelectedDateTo] = useState(null);


    useEffect(() => {
        if (location.state) {
            const state = location.state.bookingsState;
            if (state) {
                setPage(state.page);
                setRowsPerPage(state.rowsPerPage);
                setSortColumn(state.sortColumn);
                setSortDirection(state.sortDirection);
                setBookingId(state.bookingId);
                setStatusValue(state.statusValue);
                setDateValue(state.dateValue);
                setSelectedDateFrom(state.selectedDateFrom);
                setSelectedDateTo(state.selectedDateTo);
            }
        }
        setLocationParsed(true);
    }, [location]);

    const handleClearFilter = () => {
        if (isAdmin) {
            setOrganizationValue('All');
        }
        setPage(0);
        setBookingId('');
        setStatusValue('All');
        setDateValue('All');
        setSelectedDateFrom(null);
        setSelectedDateTo(null);
    };

    const handleExport = (fileType) => {
        if (!fileType) return;
        let params = {
            "file-type": fileType
        };
        if (bookingId) {
            params["booking-id"] = bookingId;
        }
        if (selectedDateFrom) {
            params["from"] = Math.floor(selectedDateFrom.getTime() / 1000);
        }
        if (selectedDateTo) {
            params["to"] = Math.floor(selectedDateTo.getTime() / 1000);
        }
        if (isAdmin && organizationValue && organizationValue !== 'All') {
            params["org"] = organizationValue;
        }
        if (statusValue && statusValue !== 'All') {
            params["status"] = statusValue;
        }
        if (dateValue && dateValue !== 'All') {
            params["date"] = dateValue;
        }
        if (sortColumn && sortDirection) {
            params["sort-by"] = sortColumn;
            params["sort"] = sortDirection;
        }
        axios.get("/api/booking/export/", {
            method: 'GET',
            responseType: 'blob',
            params: params,
        }).then((response) => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data]));
            link.setAttribute('download', getFileName(response));
            document.body.appendChild(link);
            link.click();
        }).catch((err) =>{});
    }

    const handleRowClick = (row) => {
        return () => {
            if (row['booking_id']) {
                const bookingsState = {
                    page,
                    rowsPerPage,
                    sortColumn,
                    sortDirection,
                    bookingId,
                    statusValue,
                    dateValue,
                    selectedDateFrom,
                    selectedDateTo
                };

                if (isAdmin || row.status === "Booked") {
                    history.push(`/booking/edit/${row['booking_id']}`, {
                        bookingsState: bookingsState
                    });
                } else {
                    history.push(`/booking/view/${row['booking_id']}`, {
                        bookingsState: bookingsState
                    });
                }
            }
        };
    };

    const columnSorterFn = (columnId) => {
        return () => {
            if (sortColumn === columnId) {
                if (sortDirection === "asc") {
                    setSortDirection("desc");
                } else if (sortDirection === "desc") {
                    setSortDirection("");
                } else if (sortDirection === "") {
                    setSortDirection("asc");
                }
            } else {
                setSortColumn(columnId);
                setSortDirection("asc");
            }
        };
    };

    const handleOrgValueChange = (e) => {
        setOrganizationValue(e.target.value);
    };

    const handleBookingIdChange = (e) => {
        setBookingId(e.target.value.toUpperCase());
        setPage(0);
    };

    const handleDateFromChange = (date) => {
        setSelectedDateFrom(date);
        setPage(0);
        setDateValue('All');
    };

    const handleDateToChange = (date) => {
        setSelectedDateTo(date);
        setPage(0);
        setDateValue('All');
    };

    const handleStatusValueChange = (e) => {
        setStatusValue(e.target.value);
        setPage(0);
    };

    const handleDateValueChange = (e) => {
        setDateValue(e.target.value);
        setPage(0);
        setSelectedDateFrom(null);
        setSelectedDateTo(null);
    };

    useEffect(() => {
        const getStatuses = async () => {
            axios.get("/api/booking/statuses/").then((response) => {
                if (response.status === 200) {
                    let statuses = response.data;
                    statuses.unshift('All');
                    setStatuses(statuses);
                }
            }).catch((err) => {
            });
        };

        const getOrganizations = async () => {
            axios.get("/api/orgs/").then((response) => {
                if (response.status === 200) {
                    let orgs = response.data;
                    orgs.unshift({
                        name: '',
                        code: 'All'
                    });
                    setOrganizations(orgs);
                }
            }).catch((err) => {
            });
        };

        if (isAdmin) {
            getOrganizations();
        }

        getStatuses();
    }, [isAdmin]);

    useEffect(() => {
        const loadBookings = async () => {
            if (!locationParsed) return;

            let params = {
                start: page * rowsPerPage,
                length: rowsPerPage
            };

            if (bookingId) {
                params["booking-id"] = bookingId;
            }
            if (selectedDateFrom) {
                params["from"] = Math.floor(selectedDateFrom.getTime() / 1000);
            }
            if (selectedDateTo) {
                params["to"] = Math.floor(selectedDateTo.getTime() / 1000);
            }
            if (isAdmin && organizationValue && organizationValue !== 'All') {
                params["org"] = organizationValue;
            }
            if (statusValue && statusValue !== 'All') {
                params["status"] = statusValue;
            }
            if (dateValue && dateValue !== 'All') {
                params["date"] = dateValue;
            }
            if (sortColumn && sortDirection) {
                params["sort-by"] = sortColumn;
                params["sort"] = sortDirection;
            }

            axios.get("/api/booking/", {
                params: params
            }).then((response) => {
                if (response.status === 200) {
                    setRows(response.data.data);
                    setTotalRows(response.data.recordsTotal);
                }
            }).catch((err) => {
            });
        };

        loadBookings();
    }, [page, rowsPerPage, organizationValue, bookingId, statusValue, dateValue,
        selectedDateFrom, selectedDateTo, sortColumn, sortDirection, isAdmin, locationParsed]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setPage(0);
        setRowsPerPage(e.target.value);
    };

    if (!isAdmin && !organizationId) {
        return (
            <>
                <AppBar pageName="bookings"/>
                <div className="container-fluid d-flex flex-column mt-5">
                    <Paper className="d-flex p-3 justify-content-between flex-wrap">
                        <div className="px-1 col-12 col-lg-12">
                            <div className={classes.newUserHeader}>Welcome, {name}</div>
                            <div className={classes.newUserText}>
                                Your account is active. Please now email <a
                                className={classes.link}
                                href="mailto://operations@driveferris.com"
                            >operations@driveferris.com</a> and request access to your organisation's booking space.
                            </div>
                        </div>
                    </Paper>
                </div>
            </>
        );
    }

    return (
        <>
            <AppBar pageName="bookings"/>
            <div className="container-fluid d-flex flex-column mt-5">
                <Paper className="d-flex p-3 justify-content-start flex-wrap">
                    {
                        isAdmin && <div className="px-1 col-12 col-lg-2">
                            <FormControl variant="outlined" className="w-100">
                                <InputLabel id="demo-simple-select-outlined-label-org">Organisation</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label-org"
                                    id="demo-simple-select-outlined-org"
                                    value={organizationValue}
                                    onChange={handleOrgValueChange}
                                    label="Organisation"
                                >
                                    {
                                        organizations.map((org) => {
                                            if (org.code === 'All') {
                                                return <MenuItem key={org.code} value={org.code}>All</MenuItem>
                                            } else {
                                                return <MenuItem
                                                    key={org.code}
                                                    value={org.code}
                                                >
                                                    {org.name}&nbsp;<b>{org.code}</b>
                                                </MenuItem>
                                            }
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                    }
                    <div className="px-1 col-1 col-lg-1">
                        <TextField
                            className={classes.searchField}
                            label="Our ID"
                            type="text"
                            variant="outlined"
                            value={bookingId}
                            onChange={handleBookingIdChange}
                        />
                    </div>
                    <div className="px-1 col-12 col-lg-1">
                        <FormControl variant="outlined" className="w-100">
                            <InputLabel htmlFor="outlined-type-native-simple-data-table">Status</InputLabel>
                            <Select
                                value={statusValue}
                                onChange={handleStatusValueChange}
                                label="Status"
                                inputProps={{
                                    name: "type",
                                    id: "outlined-type-native-simple-data-table",
                                }}
                                MenuProps={MenuProps}
                                renderValue={(selected) => {
                                    return selected;
                                }}
                            >
                                {statuses.map((name) => (
                                    <MenuItem key={name} value={name}>{name}
                                        {/*<ListItemText primary={name} />*/}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="px-1 col-12 col-lg-2">
                        <FormControl variant="outlined" className="w-100">
                            <InputLabel htmlFor="outlined-type-native-simple-data-table-1">Date</InputLabel>
                            <Select
                                value={dateValue}
                                onChange={handleDateValueChange}
                                label="Date"
                                inputProps={{
                                    name: "type",
                                    id: "outlined-type-native-simple-data-table-1",
                                }}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="All">All</MenuItem>
                                <MenuItem key="Today" value="Today">Today</MenuItem>
                                <MenuItem key="Next_Day" value="Next Day">Next Day</MenuItem>
                                <MenuItem key="Previous_Day" value="Previous Day">Previous Day</MenuItem>
                                <MenuItem key="Forward_7_Days" value="Forward 7 Days">Forward 7 Days</MenuItem>
                                <MenuItem key="Back_7_Days" value="Back 7 Days">Back 7 Days</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className={"px-1 d-flex justify-content-between " + (isAdmin ? "col-lg-3" : "col-lg-4")}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <FormControl variant="outlined" className="w-100 mx-2">
                                <KeyboardDatePicker
                                    disableToolbar
                                    format="EE do LLLL yyyy"
                                    variant="inline"
                                    inputVariant="outlined"
                                    label="From"
                                    autoOk
                                    value={selectedDateFrom}
                                    onChange={handleDateFromChange}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </FormControl>
                            <FormControl variant="outlined" className="w-100 mx-2">
                                <KeyboardDatePicker
                                    disableToolbar
                                    format="EE do LLLL yyyy"
                                    variant="inline"
                                    inputVariant="outlined"
                                    autoOk
                                    label="To"
                                    value={selectedDateTo}
                                    onChange={handleDateToChange}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </FormControl>
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="px-1 d-flex col-lg-1">
                        <div className={classes.clearFilterButtonContainer}>
                            <Button
                                className={classes.clearFilterButton}
                                color="secondary"
                                onClick={handleClearFilter}
                            >Clear<br/>Filter</Button>
                        </div>
                    </div>
                    <div className={"px-1 d-flex " + (isAdmin ? "col-lg-2" : "col-lg-3")}>
                        <div className={classes.exportButtonContainer}>
                            <ExportButton
                                className={classes.exportButton}
                                onClick={handleExport}
                            />
                        </div>
                    </div>
                </Paper>

                <Paper className={classes.paper}>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => {
                                        let sorted = false;
                                        let sortIcon = <></>;
                                        if (column.sortable) {
                                            sorted = column.id === sortColumn;
                                            if (sortDirection === "asc") {
                                                sortIcon = <ArrowDropUpIcon className={classes.sortIcon}/>;
                                            } else if (sortDirection === "desc") {
                                                sortIcon = <ArrowDropDownIcon className={classes.sortIcon}/>;
                                            }
                                        }
                                        return (
                                            <TableCell
                                                key={column.key}
                                                align={column.align}
                                                onClick={column.sortable && columnSorterFn(column.id)}
                                                className={classes.columnHeader}
                                            >
                                                {column.label}{sorted && sortIcon}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                            onClick={handleRowClick(row)}
                                            className={classes.tableRow}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];

                                                return (
                                                    <TableCell
                                                        key={column.key}
                                                        align={column.align}
                                                        className={classes.tableCell}
                                                    >
                                                        {column.format ? column.format(value) : value}
                                                        {column.subCell && (
                                                            <div style={{color: "#777"}}>
                                                                {row[column.subCell.id]}
                                                            </div>
                                                        )}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={totalRows}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={rowsPerPageOptions}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
        </>
    );
}
