import {format} from "date-fns";

function validatePhoneNumber(value, stateValue) {
    value = value.replace(/\D/g, '');

    if ((value.length === 1 && value !== '4') ||
        (value.length === 2 && value !== '44')) {
        return {
            value: stateValue,
            valid: false
        }
    }

    let data = value.substring(0, 12).split('');
    let len = data.length;
    for (let i=0;i<=12;i++) {
        if (i>len) {
            data.push('');
        }
    }
    let newValue = [
        data.slice(0, 2).join(''),
        data.slice(2, 6).join(''),
        data.slice(6, 9).join(''),
        data.slice(9, 12).join('')
    ].join(' ');
    for (let i=0;i<3;i++) {
        newValue = newValue.replace('  ', ' ');
    }
    if (newValue.endsWith(' ')) {
        newValue = newValue.substring(0, newValue.length - 1);
    }
    let valid = false;
    if (newValue.length === 15) {
        valid = true;
    }

    return {
        value: newValue,
        valid: valid
    };
}

function truncateDate (date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
}

function truncateSeconds (date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0);
}

function addTimeValues (baseDate, timeDate) {
    return new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate(), timeDate.getHours(), timeDate.getMinutes(), 0);
}

function addTimezone (date) {
    return new Date(date.getTime() - new Date().getTimezoneOffset() * 60000);
}

function dateRenderer (date) {
    return format(addTimezone(new Date(date)), "HH:mm dd LLLL yyyy");
}

function dateAndTimeRenderer (date) {
    return format(addTimezone(new Date(date)), "EE do LLLL yyyy HH:mm");
}

export {addTimeValues, addTimezone, dateRenderer, dateAndTimeRenderer, truncateDate, truncateSeconds, validatePhoneNumber}
