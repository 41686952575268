import React, {useContext, useEffect, useState,} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import {useHistory, useLocation} from "react-router-dom";
import AppBar from "../../components/Header";
import {Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select,} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {UserContext} from "../../contexts/user-profile";
import {addTimeValues, truncateDate, validatePhoneNumber} from "../Bookings/utils/";

const useStyles = makeStyles({
    paper: {
        width: "100%",
        marginTop: 25,
    },
    container: {
        maxHeight: 800,
    },
    buttonsBar: {
        marginRight: 20,
        marginLeft: 20,
        padding: 16,
        position: "sticky",
        top: 65,
        zIndex: 99,
    },
    textField: {
        margin: "0px 10px",
        minWidth: 200,
    },
    formControl: {
        margin: "0px 10px",
        width: '100%',
    },
    field: {
        width: "100%",
        margin: '0px 10px'
    },
    dateField: {
        marginLeft: 10,
        marginRight: 10,
        marginTop: 0,
        marginBottom: 0,
        width: '50%'
    },
    timeField: {
        marginLeft: 10,
        marginRight: 10,
        marginTop: 0,
        marginBottom: 0,
        width: '40%'
    },
    separatorLineContainer: {
        width: '100%'
    },
    separatorLine: {
        color: '#FEFEFE'
    },
    containerAlignLeft: {
        textAlign: 'left'
    },
    containerAlignRight: {
        textAlign: 'right'
    },
    sectionHeading: {
        fontSize: '16px'
    },
    cancelButton: {
        width: '100px',
        marginRight: '20px'
    },
    saveButton: {
        width: '100px'
    },
});

export default function AddBookingPage() {
    const history = useHistory();
    const classes = useStyles();

    const {isAdmin} = useContext(UserContext);

    const location = useLocation();
    const [bookingsState, setBookingsState] = useState(null);

    useEffect(() => {
        if (location.state) {
            setBookingsState(location.state.bookingsState);
        }
    }, [location]);

    const [organizations, setOrganizations] = useState([]);
    const [organizationValue, setOrganizationValue] = useState("");
    const [organizationValueInvalid, setOrganizationValueInvalid] = useState(false);

    const [minDate] = useState(truncateDate(new Date()));

    const [types, setTypes] = useState([]);
    const [vehicleModels, setVehicleModels] = useState([]);

    const [typeValue, setTypeValue] = useState("Delivery");
    const [clientRef, setClientRef] = useState("");
    const [vehicleRegistration, setVehicleRegistration] = useState("");
    const [vehicleModelValue, setVehicleModelValue] = useState("");

    const [typeValueInvalid, setTypeValueInvalid] = useState(false);
    const [vehicleRegistrationInvalid, setVehicleRegistrationInvalid] = useState(false);
    const [vehicleModelValueInvalid, setVehicleModelValueInvalid] = useState(false);
    const [collectionContactNameInvalid, setCollectionContactNameInvalid] = useState(false);
    const [collectionContactMobileInvalid, setCollectionContactMobileInvalid] = useState(false);
    const [collectionPostCodeInvalid, setCollectionPostCodeInvalid] = useState(false);
    const [deliveryContactNameInvalid, setDeliveryContactNameInvalid] = useState(false);
    const [deliveryContactMobileInvalid, setDeliveryContactMobileInvalid] = useState(false);
    const [deliveryPostCodeInvalid, setDeliveryPostCodeInvalid] = useState(false);

    const [returnVehicleRegistrationInvalid, setReturnVehicleRegistrationInvalid] = useState(false);
    const [returnVehicleModelValueInvalid, setReturnVehicleModelValueInvalid] = useState(false);
    const [returnContactNameInvalid, setReturnContactNameInvalid] = useState(false);
    const [returnContactMobileInvalid, setReturnContactMobileInvalid] = useState(false);
    const [returnPostCodeInvalid, setReturnPostCodeInvalid] = useState(false);
    const [collectionDateInvalid, setCollectionDateInvalid] = useState(false);
    const [collectionTimeInvalid, setCollectionTimeInvalid] = useState(false);
    const [deliveryDateInvalid, setDeliveryDateInvalid] = useState(false);
    const [deliveryTimeInvalid, setDeliveryTimeInvalid] = useState(false);
    const [returnDateInvalid, setReturnDateInvalid] = useState(false);
    const [returnTimeInvalid, setReturnTimeInvalid] = useState(false);

    const [collectionContactName, setCollectionContactName] = useState("");
    const [collectionContactMobile, setCollectionContactMobile] = useState("");
    const [collectionAddress, setCollectionAddress] = useState("");
    const [collectionPostCode, setCollectionPostCode] = useState("");
    const [collectionAdditionalInstructions, setCollectionAdditionalInstructions] = useState("");
    const [collectionFullInspection, setCollectionFullInspection] = useState(false);
    const [collectionNearCustomerValet, setCollectionNearCustomerValet] = useState(false);
    const [collectionProvideETA, setCollectionProvideETA] = useState(false);
    const [collectionRequiresFuel, setCollectionRequiresFuel] = useState(false);
    const [collectionIdCheck, setCollectionIdCheck] = useState(false);
    const [collectionDateValue, setCollectionDateValue] = useState(null);
    const [collectionTimeValue, setCollectionTimeValue] = useState(null);

    const [deliveryContactName, setDeliveryContactName] = useState("");
    const [deliveryContactMobile, setDeliveryContactMobile] = useState("");
    const [deliveryAddress, setDeliveryAddress] = useState("");
    const [deliveryPostCode, setDeliveryPostCode] = useState("");
    const [deliveryAdditionalInstructions, setDeliveryAdditionalInstructions] = useState("");
    const [deliveryFullInspection, setDeliveryFullInspection] = useState(false);
    const [deliveryNearCustomerValet, setDeliveryNearCustomerValet] = useState(false);
    const [deliveryProvideETA, setDeliveryProvideETA] = useState(false);
    const [deliveryRequiresFuel, setDeliveryRequiresFuel] = useState(false);
    const [deliveryIdCheck, setDeliveryIdCheck] = useState(false);
    const [deliveryDateValue, setDeliveryDateValue] = useState(null);
    const [deliveryTimeValue, setDeliveryTimeValue] = useState(null);

    const [returnVehicleRegistration, setReturnVehicleRegistration] = useState("");
    const [returnVehicleModelValue, setReturnVehicleModelValue] = useState("");
    const [returnContactName, setReturnContactName] = useState("");
    const [returnContactMobile, setReturnContactMobile] = useState("");
    const [returnAddress, setReturnAddress] = useState("");
    const [returnPostCode, setReturnPostCode] = useState("");
    const [returnAdditionalInstructions, setReturnAdditionalInstructions] = useState("");
    const [returnFullInspection, setReturnFullInspection] = useState(false);
    const [returnNearCustomerValet, setReturnNearCustomerValet] = useState(false);
    const [returnProvideETA, setReturnProvideETA] = useState(false);
    const [returnRequiresFuel, setReturnRequiresFuel] = useState(false);
    const [returnIdCheck, setReturnIdCheck] = useState(false);
    const [returnDateValue, setReturnDateValue] = useState(null);
    const [returnTimeValue, setReturnTimeValue] = useState(null);

    const [showCollectionTime, setShowCollectionTime] = useState(false);
    const [showDeliveryTime, setShowDeliveryTime] = useState(false);
    const [showReturnTime, setShowReturnTime] = useState(false);

    const [showReturnForm, setShowReturnForm] = useState(false);

    useEffect(() => {
        const getTypes = async () => {
            const response = await axios.get("/api/booking/types/");
            if (response.status === 200) {
                setTypes(response.data);
            }
        };
        const getVehicleModels = async () => {
            const response = await axios.get("/api/booking/vehicle-models/");
            if (response.status === 200) {
                setVehicleModels(response.data);
            }
        };
        const getOrganizations = async () => {
            axios.get("/api/orgs/").then((response) => {
                if (response.status === 200) {
                    setOrganizations(response.data);
                }
            }).catch((err) => {
            });
        };

        if (isAdmin) {
            getOrganizations();
        }
        getTypes();
        getVehicleModels();
    }, [isAdmin]);

    useEffect(() => {
        setShowCollectionTime(false);
        setShowDeliveryTime(false);
        setShowReturnTime(false);

        setShowReturnForm(false);

        if (typeValue === 'Other') {
            setShowCollectionTime(true);
            setShowDeliveryTime(true);
            setShowReturnTime(true);
        } else if (typeValue === 'Delivery') {
            setShowDeliveryTime(true);
        } else if (typeValue === 'Collection') {
            setShowCollectionTime(true);
        } else if (typeValue === 'Update/Flip') {
            setShowDeliveryTime(true);
            setShowReturnForm(true);
        } else if (typeValue === 'Repair') {
            setShowCollectionTime(true);
        }

    }, [typeValue]);

    const handleOrgValueChange = (e) => {
        setOrganizationValue(e.target.value);
        setOrganizationValueInvalid(false);
    };

    const handleTypeValueChange = (e) => {
        setTypeValue(e.target.value);
    }

    const handleVehicleRegistrationChange = (e) => {
        setVehicleRegistration(e.target.value.toUpperCase());
        setVehicleRegistrationInvalid(false);
    }

    const handleVehicleModelValueChange = (e) => {
        setVehicleModelValue(e.target.value);
        setVehicleModelValueInvalid(false);
    }

    const handleCollectionDateValueChange = (value) => {
        setCollectionDateValue(value);
        setCollectionDateInvalid(false);
        if (value && !deliveryDateValue) {
            setDeliveryDateValue(value);
            setDeliveryDateInvalid(false);
        }
    }

    const handleCollectionTimeValueChange = (value) => {
        setCollectionTimeValue(value);
        setCollectionTimeInvalid(false);
    }

    const handleDeliveryDateValueChange = (value) => {
        setDeliveryDateValue(value);
        setDeliveryDateInvalid(false);
    }

    const handleDeliveryTimeValueChange = (value) => {
        setDeliveryTimeValue(value);
        setDeliveryTimeInvalid(false);
    }

    const handleReturnDateValueChange = (value) => {
        setReturnDateValue(value);
        setReturnDateInvalid(false);
    }
    const handleReturnTimeValueChange = (value) => {
        setReturnTimeValue(value);
        setReturnTimeInvalid(false);
    }

    const handleClientRefChange = (e) => {
        setClientRef(e.target.value);
    }

    const handleCollectionContactNameChange = (e) => {
        setCollectionContactName(e.target.value);
        setCollectionContactNameInvalid(false);
    }
    const handleCollectionContactMobileChange = (e) => {
        let newValue = e.target.value;
        let validationResult = validatePhoneNumber(newValue, collectionContactMobile);

        setCollectionContactMobile(validationResult.value);
        setCollectionContactMobileInvalid(!validationResult.valid);
    }
    const handleCollectionAddressChange = (e) => {
        setCollectionAddress(e.target.value);
    }
    const handleCollectionPostCodeChange = (e) => {
        setCollectionPostCode(e.target.value);
        setCollectionPostCodeInvalid(false);
    }
    const handleCollectionAdditionalInstructionsChange = (e) => {
        setCollectionAdditionalInstructions(e.target.value);
    }
    const handleCollectionFullInspectionChange = (e) => {
        setCollectionFullInspection(e.target.checked);
    }
    const handleCollectionNearCustomerValetChange = (e) => {
        setCollectionNearCustomerValet(e.target.checked);
    }
    const handleCollectionProvideETAChange = (e) => {
        setCollectionProvideETA(e.target.checked);
    }
    const handleCollectionRequiresFuelChange = (e) => {
        setCollectionRequiresFuel(e.target.checked);
    }
    const handleCollectionIdCheckChange = (e) => {
        setCollectionIdCheck(e.target.checked);
    }

    const handleDeliveryContactNameChange = (e) => {
        setDeliveryContactName(e.target.value);
        setDeliveryContactNameInvalid(false);
    }
    const handleDeliveryContactMobileChange = (e) => {
        let newValue = e.target.value;
        let validationResult = validatePhoneNumber(newValue, deliveryContactMobile);

        setDeliveryContactMobile(validationResult.value);
        setDeliveryContactMobileInvalid(!validationResult.valid);
    }
    const handleDeliveryAddressChange = (e) => {
        setDeliveryAddress(e.target.value);
    }
    const handleDeliveryPostCodeChange = (e) => {
        setDeliveryPostCode(e.target.value);
        setDeliveryPostCodeInvalid(false);
    }
    const handleDeliveryAdditionalInstructionsChange = (e) => {
        setDeliveryAdditionalInstructions(e.target.value);
    }
    const handleDeliveryFullInspectionChange = (e) => {
        setDeliveryFullInspection(e.target.checked);
    }
    const handleDeliveryNearCustomerValetChange = (e) => {
        setDeliveryNearCustomerValet(e.target.checked);
    }
    const handleDeliveryProvideETAChange = (e) => {
        setDeliveryProvideETA(e.target.checked);
    }
    const handleDeliveryRequiresFuelChange = (e) => {
        setDeliveryRequiresFuel(e.target.checked);
    }
    const handleDeliveryIdCheckChange = (e) => {
        setDeliveryIdCheck(e.target.checked);
    }

    const handleReturnVehicleRegistrationChange = (e) => {
        setReturnVehicleRegistration(e.target.value.toUpperCase());
        setReturnVehicleRegistrationInvalid(false);
    }
    const handleReturnVehicleModelValueChange = (e) => {
        setReturnVehicleModelValue(e.target.value);
        setReturnVehicleModelValueInvalid(false);
    }
    const handleReturnContactNameChange = (e) => {
        setReturnContactName(e.target.value);
        setReturnContactNameInvalid(false);
    }
    const handleReturnContactMobileChange = (e) => {
        let newValue = e.target.value;
        let validationResult = validatePhoneNumber(newValue, returnContactMobile);

        setReturnContactMobile(validationResult.value);
        setReturnContactMobileInvalid(!validationResult.valid);
    }
    const handleReturnAddressChange = (e) => {
        setReturnAddress(e.target.value);
    }
    const handleReturnPostCodeChange = (e) => {
        setReturnPostCode(e.target.value);
        setReturnPostCodeInvalid(false);
    }
    const handleReturnAdditionalInstructionsChange = (e) => {
        setReturnAdditionalInstructions(e.target.value);
    }
    const handleReturnFullInspectionChange = (e) => {
        setReturnFullInspection(e.target.checked);
    }
    const handleReturnNearCustomerValetChange = (e) => {
        setReturnNearCustomerValet(e.target.checked);
    }
    const handleReturnProvideETAChange = (e) => {
        setReturnProvideETA(e.target.checked);
    }
    const handleReturnRequiresFuelChange = (e) => {
        setReturnRequiresFuel(e.target.checked);
    }
    const handleReturnIdCheckChange = (e) => {
        setReturnIdCheck(e.target.checked);
    }

    const goToBookingsList = () => {
        history.push('/bookings/', {
            bookingsState: bookingsState
        });
    };

    const handleCancelAddBooking = () => {
        goToBookingsList();
    }

    const handleSaveBooking = () => {
        let valid = true;

        if (isAdmin && !organizationValue) {
            setOrganizationValueInvalid(true);
            valid = false;
        }

        if (!typeValue) {
            setTypeValueInvalid(true);
            valid = false;
        }
        if (!vehicleRegistration) {
            setVehicleRegistrationInvalid(true);
            valid = false;
        }
        if (!vehicleModelValue) {
            setVehicleModelValueInvalid(true);
            valid = false;
        }
        // collection
        if (!collectionContactName) {
            setCollectionContactNameInvalid(true);
            valid = false;
        }
        if (!collectionContactMobile || collectionContactMobile.length !== 15 || collectionContactMobileInvalid) {
            setCollectionContactMobileInvalid(true);
            valid = false;
        }
        if (!collectionPostCode) {
            setCollectionPostCodeInvalid(true);
            valid = false;
        }
        if (!collectionDateValue) {
            setCollectionDateInvalid(true);
            valid = false;
        }
        if (showCollectionTime && !collectionTimeValue) {
            setCollectionTimeInvalid(true);
            valid = false;
        }
        // delivery
        if (!deliveryContactName) {
            setDeliveryContactNameInvalid(true);
            valid = false;
        }
        if (!deliveryContactMobile || deliveryContactMobile.length !== 15 || deliveryContactMobileInvalid) {
            setDeliveryContactMobileInvalid(true);
            valid = false;
        }
        if (!deliveryPostCode) {
            setDeliveryPostCodeInvalid(true);
            valid = false;
        }
        if (!deliveryDateValue) {
            setDeliveryDateInvalid(true);
            valid = false;
        }
        if (showDeliveryTime && !deliveryTimeValue) {
            setDeliveryTimeInvalid(true);
            valid = false;
        }

        if (showReturnForm) {
            if (!returnVehicleRegistration) {
                setReturnVehicleRegistrationInvalid(true);
                valid = false;
            }
            if (!returnVehicleModelValue) {
                setReturnVehicleModelValueInvalid(true);
                valid = false;
            }
            if (!returnContactName) {
                setReturnContactNameInvalid(true);
                valid = false;
            }
            if (!returnContactMobile || returnContactMobile.length !== 15 || returnContactMobileInvalid) {
                setReturnContactMobileInvalid(true);
                valid = false;
            }
            if (!returnPostCode) {
                setReturnPostCodeInvalid(true);
                valid = false;
            }
            if (!returnDateValue) {
                setReturnDateInvalid(true);
                valid = false;
            }
            if (showReturnTime && !returnTimeValue) {
                setReturnTimeInvalid(true);
                valid = false;
            }
        }

        if (!valid) {
            return;
        }

        let collectionDate = truncateDate(new Date(collectionDateValue));
        if (showCollectionTime) {
            collectionDate = addTimeValues(collectionDate, collectionTimeValue);
        }
        let deliveryDate = truncateDate(new Date(deliveryDateValue));
        if (showDeliveryTime) {
            deliveryDate = addTimeValues(deliveryDate, deliveryTimeValue);
        }
        let returnDate = truncateDate(new Date(returnDateValue));
        if (showReturnTime) {
            returnDate = addTimeValues(returnDate, returnTimeValue);
        }

        let formData = {
            "type": typeValue,
            "client_ref": clientRef,
            "vehicle_registration": vehicleRegistration,
            "vehicle_model": vehicleModelValue,
            "collection": {
                "contact_name": collectionContactName,
                "contact_mobile": collectionContactMobile,
                "address": collectionAddress,
                "post_code": collectionPostCode,
                "additional_instructions": collectionAdditionalInstructions,
                "full_inspection": collectionFullInspection,
                "near_customer_valet": collectionNearCustomerValet,
                "provide_eta": collectionProvideETA,
                "requires_fuel": collectionRequiresFuel,
                "id_check": collectionIdCheck,
                "action_date": collectionDate
            },
            "delivery": {
                "contact_name": deliveryContactName,
                "contact_mobile": deliveryContactMobile,
                "address": deliveryAddress,
                "post_code": deliveryPostCode,
                "additional_instructions": deliveryAdditionalInstructions,
                "full_inspection": deliveryFullInspection,
                "near_customer_valet": deliveryNearCustomerValet,
                "provide_eta": deliveryProvideETA,
                "requires_fuel": deliveryRequiresFuel,
                "id_check": deliveryIdCheck,
                "action_date": deliveryDate
            },
            "return": null
        };

        if (typeValue === 'Update/Flip') {
            formData["return"] = {
                "vehicle_registration": returnVehicleRegistration,
                "vehicle_model": returnVehicleModelValue,
                "contact_name": returnContactName,
                "contact_mobile": returnContactMobile,
                "address": returnAddress,
                "post_code": returnPostCode,
                "additional_instructions": returnAdditionalInstructions,
                "full_inspection": returnFullInspection,
                "near_customer_valet": returnNearCustomerValet,
                "provide_eta": returnProvideETA,
                "requires_fuel": returnRequiresFuel,
                "id_check": returnIdCheck,
                "action_date": returnDate
            };
        }

        if (isAdmin) {
            formData["organization_id"] = organizationValue;
            let organizationName = organizations.find((org) => org.code === organizationValue).name;
            formData["organization_name"] = organizationName;
        }

        axios.post("/api/booking/", formData).then((response) => {
            if (response.status === 200) {
                goToBookingsList();
            }
        }).catch((error) => {
            // console.log(error);
        });
    }

    return (
        <>
            <AppBar pageName="add-booking"/>
            <Paper className={classes.buttonsBar}>
                <Grid container spacing={1}>
                    <Grid item xs={12} className={classes.containerAlignRight}>
                        <Button
                            className={classes.cancelButton}
                            variant="contained"
                            size="large"
                            onClick={handleCancelAddBooking}
                        >Cancel</Button>
                        <Button
                            className={classes.saveButton}
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleSaveBooking}
                        >Save</Button>
                    </Grid>
                </Grid>
            </Paper>
            <div className="container-fluid d-flex flex-column mt-5">
                <Paper className="d-flex p-3 justify-content-between flex-wrap align-items-baseline">
                    <Grid container spacing={1}>
                        <Grid container item xs={12} spacing={1}>
                            {
                                isAdmin && <Grid container item xs={12}>
                                    <FormControl
                                        variant="outlined"
                                        className={classes.formControl}
                                    >
                                        <InputLabel id="demo-simple-select-outlined-label-org">Organisation</InputLabel>
                                        <Select
                                            error={organizationValueInvalid}
                                            labelId="demo-simple-select-outlined-label-org"
                                            id="demo-simple-select-outlined-org"
                                            value={organizationValue}
                                            onChange={handleOrgValueChange}
                                            label="Organisation"
                                        >
                                            {
                                                organizations.map((org) => (
                                                    <MenuItem key={org.code} value={org.code}>{org.name}&nbsp;
                                                        <b>{org.code}</b></MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                    <div>&nbsp;</div>
                                </Grid>
                            }
                            <Grid container item xs={3}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                >
                                    <InputLabel id="demo-simple-select-outlined-label-type">Booking Type</InputLabel>
                                    <Select
                                        error={typeValueInvalid}
                                        labelId="demo-simple-select-outlined-label-type"
                                        value={typeValue}
                                        onChange={handleTypeValueChange}
                                        label="Booking Type"
                                    >
                                        {types.map((type) => (
                                            <MenuItem key={type} value={type}>{type}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid container item xs={2}>
                                <TextField
                                    label="Client Ref"
                                    type="text"
                                    autoComplete="current-password"
                                    variant="outlined"
                                    value={clientRef}
                                    onChange={handleClientRefChange}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={3}>
                                <TextField
                                    error={vehicleRegistrationInvalid}
                                    label="Vehicle Registration"
                                    type="text"
                                    autoComplete="current-password"
                                    variant="outlined"
                                    value={vehicleRegistration}
                                    onChange={handleVehicleRegistrationChange}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={3}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel id="demo-simple-select-outlined-label-col">
                                        Vehicle Model
                                    </InputLabel>
                                    <Select
                                        error={vehicleModelValueInvalid}
                                        labelId="demo-simple-select-outlined-label-col"
                                        value={vehicleModelValue}
                                        onChange={handleVehicleModelValueChange}
                                        label="Vehicle Model"
                                    >
                                        {vehicleModels.map((v) => (
                                            <MenuItem key={v} value={v}>{v}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>&nbsp;</Grid>
                        <Grid container item xs={12}>
                            <span className={classes.sectionHeading}>Collected From</span>
                        </Grid>
                        <Grid container item xs={12}></Grid>
                        <Grid container item xs={5} spacing={2} justify="space-around">
                            <Grid container item xs={6} spacing={1}>
                                <TextField
                                    error={collectionContactNameInvalid}
                                    label="Contact Name"
                                    type="text"
                                    variant="outlined"
                                    value={collectionContactName}
                                    onChange={handleCollectionContactNameChange}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={6} spacing={1}>
                                <TextField
                                    error={collectionContactMobileInvalid}
                                    label="Contact Mobile"
                                    placeholder="Format: 44 xxxx xxx xxx"
                                    type="text"
                                    variant="outlined"
                                    value={collectionContactMobile}
                                    onChange={handleCollectionContactMobileChange}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <TextField
                                    label="Address"
                                    type="text"
                                    variant="outlined"
                                    value={collectionAddress}
                                    onChange={handleCollectionAddressChange}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <TextField
                                    error={collectionPostCodeInvalid}
                                    label="Post Code"
                                    type="text"
                                    variant="outlined"
                                    value={collectionPostCode}
                                    onChange={handleCollectionPostCodeChange}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={12} spacing={1} justify="space-between">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        className={classes.dateField}
                                        error={collectionDateInvalid}
                                        disableToolbar
                                        clearable="true"
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        minDate={minDate}
                                        autoOk={true}
                                        inputVariant="outlined"
                                        label="Collection Date"
                                        value={collectionDateValue}
                                        margin="normal"
                                        onChange={handleCollectionDateValueChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                    {
                                        showCollectionTime && <KeyboardTimePicker
                                            className={classes.timeField}
                                            error={collectionTimeInvalid}
                                            ampm={false}
                                            clearable="true"
                                            variant="inline"
                                            margin="normal"
                                            inputVariant="outlined"
                                            label="Collection Time"
                                            value={collectionTimeValue}
                                            onChange={handleCollectionTimeValueChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        />
                                    }
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <Grid container item xs={4} spacing={1}>
                            <TextField
                                multiline
                                rowsMax={12}
                                rows={12}
                                placeholder="Additional Instructions"
                                type="textarea"
                                variant="outlined"
                                value={collectionAdditionalInstructions}
                                onChange={handleCollectionAdditionalInstructionsChange}
                                className={classes.field}
                            />
                        </Grid>
                        <Grid container item xs={3} spacing={1}>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={collectionFullInspection}
                                                onChange={handleCollectionFullInspectionChange}
                                            />
                                        }
                                        label="Full Inspection"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={collectionNearCustomerValet}
                                                onChange={handleCollectionNearCustomerValetChange}
                                            />
                                        }
                                        label="Near-Customer Valet"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={collectionProvideETA}
                                                onChange={handleCollectionProvideETAChange}
                                            />
                                        }
                                        label="Driver to provide ETA"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={collectionRequiresFuel}
                                                onChange={handleCollectionRequiresFuelChange}
                                            />
                                        }
                                        label="Requires Fuel"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={collectionIdCheck}
                                                onChange={handleCollectionIdCheckChange}
                                            />
                                        }
                                        label="ID check"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12}>&nbsp;</Grid>
                    <Grid container item xs={12}>&nbsp;</Grid>
                    <div className={classes.separatorLineContainer}>
                        <hr className={classes.separatorLine}/>
                    </div>
                    <Grid container item xs={12}>&nbsp;</Grid>

                    <Grid container spacing={1}>
                        <Grid container item xs={12}>
                            <span className={classes.sectionHeading}>Delivered To</span>
                        </Grid>
                        <Grid container item xs={12}></Grid>
                        <Grid container item xs={5} spacing={2} justify="space-around">
                            <Grid container item xs={6} spacing={1}>
                                <TextField
                                    error={deliveryContactNameInvalid}
                                    label="Contact Name"
                                    type="text"
                                    variant="outlined"
                                    value={deliveryContactName}
                                    onChange={handleDeliveryContactNameChange}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={6} spacing={1}>
                                <TextField
                                    error={deliveryContactMobileInvalid}
                                    label="Contact Mobile"
                                    placeholder="Format: 44 xxxx xxx xxx"
                                    type="text"
                                    variant="outlined"
                                    value={deliveryContactMobile}
                                    onChange={handleDeliveryContactMobileChange}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <TextField
                                    label="Address"
                                    type="text"
                                    variant="outlined"
                                    value={deliveryAddress}
                                    onChange={handleDeliveryAddressChange}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <TextField
                                    error={deliveryPostCodeInvalid}
                                    label="Post Code"
                                    type="text"
                                    variant="outlined"
                                    value={deliveryPostCode}
                                    onChange={handleDeliveryPostCodeChange}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={12} spacing={1} justify="space-between">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        className={classes.dateField}
                                        error={deliveryDateInvalid}
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        minDate={minDate}
                                        autoOk={true}
                                        inputVariant="outlined"
                                        margin="normal"
                                        label="Delivery Date"
                                        value={deliveryDateValue}
                                        onChange={handleDeliveryDateValueChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                    {
                                        showDeliveryTime && <KeyboardTimePicker
                                            className={classes.timeField}
                                            error={deliveryTimeInvalid}
                                            ampm={false}
                                            clearable="true"
                                            margin="normal"
                                            variant="inline"
                                            inputVariant="outlined"
                                            label="Delivery Time"
                                            value={deliveryTimeValue}
                                            onChange={handleDeliveryTimeValueChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        />
                                    }
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <Grid container item xs={4} spacing={1}>
                            <TextField
                                multiline
                                rowsMax={12}
                                rows={12}
                                placeholder="Additional Instructions"
                                type="textarea"
                                variant="outlined"
                                value={deliveryAdditionalInstructions}
                                onChange={handleDeliveryAdditionalInstructionsChange}
                                className={classes.field}
                            />
                        </Grid>
                        <Grid container item xs={3} spacing={1}>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={deliveryFullInspection}
                                                onChange={handleDeliveryFullInspectionChange}
                                            />
                                        }
                                        label="Full Inspection"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={deliveryNearCustomerValet}
                                                onChange={handleDeliveryNearCustomerValetChange}
                                            />
                                        }
                                        label="Near-Customer Valet"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={deliveryProvideETA}
                                                onChange={handleDeliveryProvideETAChange}
                                            />
                                        }
                                        label="Driver to provide ETA"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={deliveryRequiresFuel}
                                                onChange={handleDeliveryRequiresFuelChange}
                                            />
                                        }
                                        label="Requires Fuel"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={deliveryIdCheck}
                                                onChange={handleDeliveryIdCheckChange}
                                            />
                                        }
                                        label="ID check"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        showReturnForm && <>
                            <Grid container item xs={12}>&nbsp;</Grid>
                            <Grid container item xs={12}>&nbsp;</Grid>
                            <div className={classes.separatorLineContainer}>
                                <hr className={classes.separatorLine}/>
                            </div>
                            <Grid container item xs={12}>&nbsp;</Grid>

                            <Grid container spacing={1}>
                                <Grid container item xs={5}></Grid>
                                <Grid container item xs={3}>
                                    <TextField
                                        error={returnVehicleRegistrationInvalid}
                                        label="Vehicle Registration"
                                        type="text"
                                        autoComplete="current-password"
                                        variant="outlined"
                                        value={returnVehicleRegistration}
                                        onChange={handleReturnVehicleRegistrationChange}
                                        className={classes.field}
                                    />
                                </Grid>
                                <Grid container item xs={3}>
                                    <FormControl
                                        variant="outlined"
                                        className={classes.formControl}
                                        fullWidth
                                    >
                                        <InputLabel id="demo-simple-select-outlined-label-ret">
                                            Vehicle Model
                                        </InputLabel>
                                        <Select
                                            error={returnVehicleModelValueInvalid}
                                            labelId="demo-simple-select-outlined-label-ret"
                                            value={returnVehicleModelValue}
                                            onChange={handleReturnVehicleModelValueChange}
                                            label="Vehicle Model"
                                        >
                                            {vehicleModels.map((v) => (
                                                <MenuItem key={v} value={v}>{v}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={1}></Grid>
                                <Grid container item xs={12}>
                                    <span className={classes.sectionHeading}>Return Vehicle To</span>
                                </Grid>
                                <Grid container item xs={12}></Grid>
                                <Grid container item xs={5} spacing={2} justify="space-around">
                                    <Grid container item xs={6} spacing={1}>
                                        <TextField
                                            error={returnContactNameInvalid}
                                            label="Contact Name"
                                            type="text"
                                            variant="outlined"
                                            value={returnContactName}
                                            onChange={handleReturnContactNameChange}
                                            className={classes.field}
                                        />
                                    </Grid>
                                    <Grid container item xs={6} spacing={1}>
                                        <TextField
                                            error={returnContactMobileInvalid}
                                            label="Contact Mobile"
                                            placeholder="Format: 44 xxxx xxx xxx"
                                            type="text"
                                            variant="outlined"
                                            value={returnContactMobile}
                                            onChange={handleReturnContactMobileChange}
                                            className={classes.field}
                                        />
                                    </Grid>
                                    <Grid container item xs={12} spacing={1}>
                                        <TextField
                                            label="Address"
                                            type="text"
                                            variant="outlined"
                                            value={returnAddress}
                                            onChange={handleReturnAddressChange}
                                            className={classes.field}
                                        />
                                    </Grid>
                                    <Grid container item xs={12} spacing={1}>
                                        <TextField
                                            error={returnPostCodeInvalid}
                                            label="Post Code"
                                            type="text"
                                            variant="outlined"
                                            value={returnPostCode}
                                            onChange={handleReturnPostCodeChange}
                                            className={classes.field}
                                        />
                                    </Grid>
                                    <Grid container item xs={12} spacing={1} justify="space-between">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                className={classes.dateField}
                                                error={returnDateInvalid}
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                minDate={minDate}
                                                autoOk={true}
                                                inputVariant="outlined"
                                                margin="normal"
                                                label="Return Date"
                                                value={returnDateValue}
                                                onChange={handleReturnDateValueChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                            {
                                                showReturnTime && <KeyboardTimePicker
                                                    className={classes.timeField}
                                                    error={returnTimeInvalid}
                                                    ampm={false}
                                                    clearable="true"
                                                    margin="normal"
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    label="Return Time"
                                                    value={returnTimeValue}
                                                    onChange={handleReturnTimeValueChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                />
                                            }
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={4} spacing={1}>
                                    <TextField
                                        multiline
                                        rowsMax={12}
                                        rows={12}
                                        placeholder="Additional Instructions"
                                        type="textarea"
                                        variant="outlined"
                                        value={returnAdditionalInstructions}
                                        onChange={handleReturnAdditionalInstructionsChange}
                                        className={classes.field}
                                    />
                                </Grid>
                                <Grid container item xs={3} spacing={1}>
                                    <Grid container item xs={12} spacing={1}>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormControlLabel
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        color="secondary"
                                                        checked={returnFullInspection}
                                                        onChange={handleReturnFullInspectionChange}
                                                    />
                                                }
                                                label="Full Inspection"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={12} spacing={1}>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormControlLabel
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        color="secondary"
                                                        checked={returnNearCustomerValet}
                                                        onChange={handleReturnNearCustomerValetChange}
                                                    />
                                                }
                                                label="Near-Customer Valet"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={12} spacing={1}>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormControlLabel
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        color="secondary"
                                                        checked={returnProvideETA}
                                                        onChange={handleReturnProvideETAChange}
                                                    />
                                                }
                                                label="Driver to provide ETA"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={12} spacing={1}>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormControlLabel
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        color="secondary"
                                                        checked={returnRequiresFuel}
                                                        onChange={handleReturnRequiresFuelChange}
                                                    />
                                                }
                                                label="Requires Fuel"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={12} spacing={1}>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormControlLabel
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        color="secondary"
                                                        checked={returnIdCheck}
                                                        onChange={handleReturnIdCheckChange}
                                                    />
                                                }
                                                label="ID check"
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    }
                    <Grid container item xs={12}>&nbsp;</Grid>
                    <Grid container item xs={12}>&nbsp;</Grid>
                    <div className={classes.separatorLineContainer}>
                        <hr className={classes.separatorLine}/>
                    </div>
                    <Grid container item xs={12}>&nbsp;</Grid>
                </Paper>
            </div>
        </>
    );
}
