import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";

export const UserContext = React.createContext({});

export const UserContextProvider = (props) => {
  const [profile, setProfile] = useState({
    isAdmin: false,
    organizationId: "",
    organizationName: "",
    name: "",
    email: "",
    picture: "",
    userId: ""
  });
  const [profileReceived, setProfileReceived] = useState(false);

  useEffect(() => {
    const getUserInfo = async () => {
      setProfileReceived(false);
      const userInfo = await axios.get("/api/user-info/",{
        validateStatus: function (status) {
          return ((status >= 200 && status < 300) || status === 401);
        },
      });

      if (userInfo.status === 200) {
        let profile = userInfo.data.profile;

        setProfile({
          isAdmin: profile["is_admin"],
          organizationId: profile["organization_id"],
          organizationName: profile["organization_name"],
          name: profile["name"],
          email: profile["email"],
          picture: profile["picture"],
          userId: profile["user_id"]
        });
        setProfileReceived(true);
      }
      if (userInfo.status === 401) {
        window.location.href = '/login/';
      }
    };

    getUserInfo();
  }, []);

  const value = useMemo(() => {
    return profile;
  }, [profile]);

  return (
    <UserContext.Provider value={value}>
      {profileReceived ? props.children : null}
    </UserContext.Provider>
  );
};
