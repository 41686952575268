import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {dateAndTimeRenderer} from "../Bookings/utils";

const useStyles = makeStyles({
    paper: {
        width: "100%",
        marginTop: 25,
    },
    container: {
        maxHeight: 800,
    },
    statusActive: {
        color: 'darkgreen'
    },
    statusBooked: {
        color: 'darkblue'
    },
    additionalFields: {
        color: 'darkblue'
    },
    tableCell: {
        verticalAlign: 'top'
    },
    tableRow: {
        cursor: 'pointer'
    },
    success: {
        color: 'green',
        textAlign: 'center'
    },
    fail: {
        color: 'goldenrod',
        textAlign: 'center'
    },
});

export default function ImportResult({title, success, bookings}) {

    const bookingDateRenderer = (date) => {
        if (date) {
            return dateAndTimeRenderer(date);
        }
    };

    const detailsRenderer = (data) => {
        if (data instanceof Object) {
            return data["post_code"];
        }
    };

    const columns = [];

    if (success) {
        columns.push({
            id: "booking_date",
            key: "column_booking_date",
            label: "Ref Date",
            format: bookingDateRenderer
        }, {
            id: "booking_id",
            key: "column_booking_id",
            label: "Our ID"
        });
    }

    columns.push({
            id: "client_ref",
            key: "column_client_ref",
            label: "Client Ref",
        }, {
            id: "type",
            key: "column_type",
            label: "Type",
        }, {
            id: "vehicle_model",
            key: "column_vehicle_model",
            label: "Vehicle Model",
        }, {
            id: "vehicle_registration",
            key: "column_vehicle_registration",
            label: "Vehicle Reg.",
        });

        if (success) {
            columns.push({
                id: "collection",
                key: "column_collection_details",
                label: "Collection",
                format: detailsRenderer
            }, {
                id: "delivery",
                key: "column_delivery_details",
                label: "Delivery",
                format: detailsRenderer
            }, {
                id: "return",
                key: "column_return_details",
                label: "Return",
                format: detailsRenderer
            }, {
                id: "tier",
                sortable: true,
                key: "column_tier",
                label: "Tier",
                align: "center"
            });
        }

        if (!success) {
            columns.push({
                id: "skip_reason",
                sortable: true,
                key: "column_skip_reason",
                label: "Reason To Skip",
                align: "center"
            });
        }

    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <h3 className={success ? classes.success : classes.fail}>{title}</h3>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {
                                columns.map((column) => {
                                    return <TableCell
                                        key={column.key}
                                    >
                                        {column.label}
                                    </TableCell>;
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            bookings && bookings.map((row) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.booking_id}
                                        className={classes.tableRow}
                                    >
                                        {columns.map((column) => {
                                            const value = row[column.id];

                                            return (
                                                <TableCell
                                                    className={classes.tableCell}
                                                    key={column.key}
                                                >
                                                    {column.format ? column.format(value) : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
