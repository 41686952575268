import React, {useContext, useState} from "react";
import Paper from "@material-ui/core/Paper";
import AppBar from "../../components/Header";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {UserContext} from "../../contexts/user-profile";
import Button from "@material-ui/core/Button";
import axios from "axios";

const useStyles = makeStyles((theme) =>
    createStyles({
        label: {
            fontWeight: 'bold',
            textAlign: 'right'
        },
        value: {
            textAlign: 'left'
        },
        body: {
            display: 'table-row-group'
        },
        row: {
            display: 'table-row'
        },
        cell: {
            display: 'table-cell',
            padding: '3px 10px'
        },
        resetPasswordButton: {
            width: '100px'
        },
        successMessage: {
            color: '#1D8A8B'
        },
        failMessage: {
            color: 'red'
        },
    })
);

export default function SettingsPage() {
    const classes = useStyles();

    const {userId, name, email, organizationName} = useContext(UserContext);

    const [showResetPassword] = useState(userId && userId.startsWith("auth0|"));
    const [successMessage, setSuccessMessage] = useState("");
    const [failMessage, setFailMessage] = useState("");

    const handlePasswordReset = () => {
        setSuccessMessage("");
        setFailMessage("");
        axios.post("/api/booking/reset-password/").then((response) => {
            if (response.status === 200) {
                setSuccessMessage(response.data);
            } else {
                setFailMessage("Couldn't reset password");
            }
        }).catch(() => {
            setFailMessage("Couldn't reset password");
        });
    };

    return (
        <>
            <AppBar pageName="settings"/>
            <div className="container-fluid d-flex flex-column mt-5">
                <Paper className="d-flex p-3 justify-content-between flex-wrap">
                    <div className="px-1 col-12 col-lg-12">
                        <div className={classes.body}>
                            <div className={classes.row}>
                                <div className={`${classes.cell} ${classes.label}`}>Name:</div>
                                <div className={`${classes.cell} ${classes.value}`}>{name}</div>
                            </div>
                            <div className={classes.row}>
                                <div className={`${classes.cell} ${classes.label}`}>Organisation:</div>
                                <div className={`${classes.cell} ${classes.value}`}>{organizationName}</div>
                            </div>
                            <div className={classes.row}>
                                <div className={`${classes.cell} ${classes.label}`}>Email:</div>
                                <div className={`${classes.cell} ${classes.value}`}>{email}</div>
                            </div>
                            {
                                showResetPassword && <div className={classes.row}>
                                    <div className={`${classes.cell} ${classes.label}`}>Password:</div>
                                    <div className={`${classes.cell} ${classes.value}`}>
                                        <Button
                                            className={classes.resetPasswordButton}
                                            color="secondary"
                                            onClick={handlePasswordReset}
                                        >Reset</Button>
                                    </div>
                                </div>
                            }
                            <div className={classes.row}>
                                <div className={`${classes.cell} ${classes.label}`}></div>
                                <div className={`${classes.cell} ${classes.value} ${classes.successMessage}`}>{successMessage}</div>
                            </div>
                            <div className={classes.row}>
                                <div className={`${classes.cell} ${classes.label}`}></div>
                                <div className={`${classes.cell} ${classes.value} ${classes.failMessage}`}>{failMessage}</div>
                            </div>
                        </div>
                    </div>
                </Paper>
            </div>
        </>
    );
}
