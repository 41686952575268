import React, {useContext, useEffect, useState,} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import {useHistory, useParams, useLocation} from "react-router-dom";
import AppBar from "../../components/Header";
import {Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Tooltip,} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {ChevronLeft} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import {UserContext} from "../../contexts/user-profile";
import {addTimeValues, dateRenderer, truncateDate, truncateSeconds, validatePhoneNumber} from "../Bookings/utils/";

const renderDateDeltaText = (seconds) => {
    let text = '';

    let years = Math.floor(seconds / 31536000);
    if (years > 0) {
        return years + ' Year' + (years > 1 ? 's' : '');
    }

    let months = Math.floor(seconds / 2592000);
    if (months > 0) {
        return months + ' Month' + (months > 1 ? 's' : '');
    }

    let days = Math.floor(seconds / 86400);
    if (days > 0) {
        text += days + ' Day' + (days > 1 ? 's' : '');
    }

    let hours = Math.floor((seconds - days * 86400) / 3600);
    if (text) text += ' ';
    text += hours + ' Hr' + (hours > 1 ? 's' : '');

    // let minutes = Math.floor((seconds - (days * 86400 + hours * 3600)) / 60);
    // if (text) text += ', ';
    // text += minutes + ' Minute' + (minutes > 1 ? 's' : '');
    //
    // let seconds_remainder = Math.floor(seconds - (days * 86400 + hours * 3600 + minutes * 60));
    // if (text) text += ', ';
    // text += seconds_remainder + ' Second' + (seconds_remainder > 1 ? 's' : '');

    return text;
};

const useStyles = makeStyles({
    paper: {
        width: "100%",
        marginTop: 25,
    },
    container: {
        maxHeight: 800,
    },
    buttonsBar: {
        marginRight: 20,
        marginLeft: 20,
        padding: 16,
        position: "sticky",
        top: 65,
        zIndex: 99,
    },
    textField: {
        margin: "0px 10px",
        minWidth: 200,
    },
    formControl: {
        margin: "0px 10px",
        width: '100%',
    },
    field: {
        width: "100%",
        margin: '0px 10px'
    },
    backButton: {
        width: '100px'
    },
    cancelButton: {
        width: '100px',
        marginRight: '20px'
    },
    saveButton: {
        width: '100px'
    },
    statusLabel: {
        width: '100%',
        fontSize: '14px',
        padding: '15px 5px',
        textAlign: 'right',
        lineHeight: '30px'
    },
    statusButton: {
        minWidth: '120px',
        marginTop: '10px',
        marginLeft: '10px',
        padding: '10px'
    },
    dateField: {
        marginLeft: 10,
        marginRight: 10,
        marginTop: 0,
        marginBottom: 0,
        width: '50%'
    },
    timeField: {
        marginLeft: 10,
        marginRight: 10,
        marginTop: 0,
        marginBottom: 0,
        width: '40%'
    },
    countdownText: {
        color: '#1D8A8B',
        width: '100%',
        fontSize: '14px',
        padding: '15px 5px',
        textAlign: 'center',
        lineHeight: '30px'
    },
    separatorLineContainer: {
        width: '100%'
    },
    separatorLine: {
        color: '#FEFEFE'
    },
    containerAlignLeft: {
        textAlign: 'left'
    },
    containerAlignRight: {
        textAlign: 'right'
    },
    sectionHeading: {
        fontSize: '16px'
    }
});

export default function EditBookingPage() {
    const history = useHistory();
    const classes = useStyles();
    const {id} = useParams();

    const {isAdmin} = useContext(UserContext);

    const location = useLocation();

    const [bookingsState, setBookingsState] = useState(null);

    useEffect(() => {
        if (location.state) {
            setBookingsState(location.state.bookingsState);
        }
    }, [location]);

    const [originalRecord, setOriginalRecord] = useState({});
    const [showChangesWarningDialog, setShowChangesWarningDialog] = useState(false);

    const [organizationValue, setOrganizationValue] = useState("");
    const [organizationNameValue, setOrganizationNameValue] = useState("");

    const [cancelRate, setCancelRate] = useState("");

    const [confirmStatus, setConfirmStatus] = useState("");
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const [vehicleModels, setVehicleModels] = useState([]);

    const [recordId, setRecordId] = useState("");
    const [bookingId, setBookingId] = useState("");
    const [modifiedAt, setModifiedAt] = useState("");
    const [modifiedBy, setModifiedBy] = useState("");
    const [status, setStatus] = useState("");
    const [typeValue, setTypeValue] = useState("");
    const [clientRef, setClientRef] = useState("");
    const [vehicleRegistration, setVehicleRegistration] = useState("");
    const [vehicleModelValue, setVehicleModelValue] = useState("");
    const [timeUntilActive, setTimeUntilActive] = useState(0);
    const [timeUntilActiveText, setTimeUntilActiveText] = useState("");
    const [showCountdown, setShowCountdown] = useState(false);

    const [typeValueInvalid, setTypeValueInvalid] = useState(false);
    const [vehicleRegistrationInvalid, setVehicleRegistrationInvalid] = useState(false);
    const [vehicleModelValueInvalid, setVehicleModelValueInvalid] = useState(false);
    const [collectionContactNameInvalid, setCollectionContactNameInvalid] = useState(false);
    const [collectionContactMobileInvalid, setCollectionContactMobileInvalid] = useState(false);
    const [collectionPostCodeInvalid, setCollectionPostCodeInvalid] = useState(false);
    const [deliveryContactNameInvalid, setDeliveryContactNameInvalid] = useState(false);
    const [deliveryContactMobileInvalid, setDeliveryContactMobileInvalid] = useState(false);
    const [deliveryPostCodeInvalid, setDeliveryPostCodeInvalid] = useState(false);

    const [returnVehicleRegistrationInvalid, setReturnVehicleRegistrationInvalid] = useState(false);
    const [returnVehicleModelValueInvalid, setReturnVehicleModelValueInvalid] = useState(false);
    const [returnContactNameInvalid, setReturnContactNameInvalid] = useState(false);
    const [returnContactMobileInvalid, setReturnContactMobileInvalid] = useState(false);
    const [returnPostCodeInvalid, setReturnPostCodeInvalid] = useState(false);
    const [collectionDateInvalid, setCollectionDateInvalid] = useState(false);
    const [collectionTimeInvalid, setCollectionTimeInvalid] = useState(false);
    const [deliveryDateInvalid, setDeliveryDateInvalid] = useState(false);
    const [deliveryTimeInvalid, setDeliveryTimeInvalid] = useState(false);
    const [returnDateInvalid, setReturnDateInvalid] = useState(false);
    const [returnTimeInvalid, setReturnTimeInvalid] = useState(false);

    const [collectionContactName, setCollectionContactName] = useState("");
    const [collectionContactMobile, setCollectionContactMobile] = useState("");
    const [collectionAddress, setCollectionAddress] = useState("");
    const [collectionPostCode, setCollectionPostCode] = useState("");
    const [collectionAdditionalInstructions, setCollectionAdditionalInstructions] = useState("");
    const [collectionFullInspection, setCollectionFullInspection] = useState(false);
    const [collectionNearCustomerValet, setCollectionNearCustomerValet] = useState(false);
    const [collectionProvideETA, setCollectionProvideETA] = useState(false);
    const [collectionRequiresFuel, setCollectionRequiresFuel] = useState(false);
    const [collectionIdCheck, setCollectionIdCheck] = useState(false);
    const [collectionDateValue, setCollectionDateValue] = useState(null);
    const [collectionTimeValue, setCollectionTimeValue] = useState(null);

    const [deliveryContactName, setDeliveryContactName] = useState("");
    const [deliveryContactMobile, setDeliveryContactMobile] = useState("");
    const [deliveryAddress, setDeliveryAddress] = useState("");
    const [deliveryPostCode, setDeliveryPostCode] = useState("");
    const [deliveryAdditionalInstructions, setDeliveryAdditionalInstructions] = useState("");
    const [deliveryFullInspection, setDeliveryFullInspection] = useState(false);
    const [deliveryNearCustomerValet, setDeliveryNearCustomerValet] = useState(false);
    const [deliveryProvideETA, setDeliveryProvideETA] = useState(false);
    const [deliveryRequiresFuel, setDeliveryRequiresFuel] = useState(false);
    const [deliveryIdCheck, setDeliveryIdCheck] = useState(false);
    const [deliveryDateValue, setDeliveryDateValue] = useState(null);
    const [deliveryTimeValue, setDeliveryTimeValue] = useState(null);

    const [returnVehicleRegistration, setReturnVehicleRegistration] = useState("");
    const [returnVehicleModelValue, setReturnVehicleModelValue] = useState("");
    const [returnContactName, setReturnContactName] = useState("");
    const [returnContactMobile, setReturnContactMobile] = useState("");
    const [returnAddress, setReturnAddress] = useState("");
    const [returnPostCode, setReturnPostCode] = useState("");
    const [returnAdditionalInstructions, setReturnAdditionalInstructions] = useState("");
    const [returnFullInspection, setReturnFullInspection] = useState(false);
    const [returnNearCustomerValet, setReturnNearCustomerValet] = useState(false);
    const [returnProvideETA, setReturnProvideETA] = useState(false);
    const [returnRequiresFuel, setReturnRequiresFuel] = useState(false);
    const [returnIdCheck, setReturnIdCheck] = useState(false);
    const [returnDateValue, setReturnDateValue] = useState(null);
    const [returnTimeValue, setReturnTimeValue] = useState(null);

    const [showCollectionTime, setShowCollectionTime] = useState(false);
    const [showDeliveryTime, setShowDeliveryTime] = useState(false);
    const [showReturnTime, setShowReturnTime] = useState(false);

    const [showReturnForm, setShowReturnForm] = useState(false);

    const [allowEditing, setAllowEditing] = useState(false);

    const handleCancelStatus = () => {
        setCancelRate("");
        axios.get("/api/booking/cancel-rate/", {
            params: {
                id: recordId
            }
        }).then((response) => {
            if (response.status === 200) {
                setCancelRate(response.data);
                setShowConfirmDialog(true);
            }
        }).catch((error) => {
            setShowConfirmDialog(true);
        });
    };

    useEffect(() => {
        const fillBookingData = (booking) => {
            if (isAdmin) {
                setOrganizationValue(booking.organization_id);
                setOrganizationNameValue(booking.organization_name);
            }

            setRecordId(booking.id);
            setBookingId(booking.booking_id);
            setModifiedAt(dateRenderer(booking.modified_at));
            setModifiedBy(booking.modified_name);
            setStatus(booking.status);
            setAllowEditing(isAdmin || booking.status === 'Booked');
            setTypeValue(booking.type);
            setClientRef(booking.client_ref);
            setVehicleRegistration(booking.vehicle_registration);
            setVehicleModelValue(booking.vehicle_model);
            if (booking.status === 'Booked' && booking['time_until_active'] > 0) {
                setTimeUntilActive(booking['time_until_active']);
                setTimeUntilActiveText(renderDateDeltaText(booking['time_until_active']));
                setShowCountdown(true);
            }

            if (booking.collection) {
                let collection = booking.collection;
                setCollectionContactName(collection.contact_name);
                setCollectionContactMobile(collection.contact_mobile);
                setCollectionAddress(collection.address);
                setCollectionPostCode(collection.post_code);
                setCollectionAdditionalInstructions(collection.additional_instructions);
                setCollectionFullInspection(collection.full_inspection);
                setCollectionNearCustomerValet(collection.near_customer_valet);
                setCollectionProvideETA(collection.provide_eta);
                setCollectionRequiresFuel(collection.requires_fuel);
                setCollectionIdCheck(collection.id_check);
                setCollectionDateValue(collection.action_date);
                setCollectionTimeValue(collection.action_date);
            }

            if (booking.delivery) {
                let delivery = booking.delivery;
                setDeliveryContactName(delivery.contact_name);
                setDeliveryContactMobile(delivery.contact_mobile);
                setDeliveryAddress(delivery.address);
                setDeliveryPostCode(delivery.post_code);
                setDeliveryAdditionalInstructions(delivery.additional_instructions);
                setDeliveryFullInspection(delivery.full_inspection);
                setDeliveryNearCustomerValet(delivery.near_customer_valet);
                setDeliveryProvideETA(delivery.provide_eta);
                setDeliveryRequiresFuel(delivery.requires_fuel);
                setDeliveryIdCheck(delivery.id_check);
                setDeliveryDateValue(delivery.action_date);
                setDeliveryTimeValue(delivery.action_date);
            }

            if (booking['return']) {
                let returnObj = booking['return'];
                setReturnVehicleRegistration(returnObj.vehicle_registration);
                setReturnVehicleModelValue(returnObj.vehicle_model);
                setReturnContactName(returnObj.contact_name);
                setReturnContactMobile(returnObj.contact_mobile);
                setReturnAddress(returnObj.address);
                setReturnPostCode(returnObj.post_code);
                setReturnAdditionalInstructions(returnObj.additional_instructions);
                setReturnFullInspection(returnObj.full_inspection);
                setReturnNearCustomerValet(returnObj.near_customer_valet);
                setReturnProvideETA(returnObj.provide_eta);
                setReturnRequiresFuel(returnObj.requires_fuel);
                setReturnIdCheck(returnObj.id_check);
                setReturnDateValue(returnObj.action_date);
                setReturnTimeValue(returnObj.action_date);
            }
        };

        axios.get("/api/booking/get/?booking-id=" + id).then((response) => {
            if (response.status === 200 && response.data) {
                let bookingData = response.data;
                if (bookingData) {
                    fillBookingData(bookingData);

                    setOriginalRecord({
                        "status": bookingData["status"],
                        "client_ref": bookingData["client_ref"],
                        "vehicle_registration": bookingData["vehicle_registration"],
                        "vehicle_model": bookingData["vehicle_model"],
                        "collection": bookingData["collection"],
                        "delivery": bookingData["delivery"],
                        "return": bookingData["return"]
                    });
                }
            }
        }).catch(() => {});

        // axios.get("/api/booking/types/").then((response) => {
        //     if (response.status === 200) {
        //         setTypes(response.data);
        //     }
        // });
        axios.get("/api/booking/vehicle-models/").then((response) => {
            if (response.status === 200) {
                setVehicleModels(response.data);
            }
        });
    }, [id, isAdmin]);

    useEffect(() => {
        if (timeUntilActive > 0) {
            setTimeout(() => {
                setTimeUntilActiveText(renderDateDeltaText(timeUntilActive - 1));
                setTimeUntilActive(timeUntilActive - 1);
            }, 1000);
        } else {
            setShowCountdown(false);
        }
    }, [timeUntilActive]);

    useEffect(() => {
        setShowCollectionTime(false);
        setShowDeliveryTime(false);
        setShowReturnTime(false);

        setShowReturnForm(false);

        if (typeValue === 'Other') {
            setShowCollectionTime(true);
            setShowDeliveryTime(true);
            setShowReturnTime(true);
        } else if (typeValue === 'Delivery') {
            setShowDeliveryTime(true);
        } else if (typeValue === 'Collection') {
            setShowCollectionTime(true);
        } else if (typeValue === 'Update/Flip') {
            setShowDeliveryTime(true);
            setShowReturnForm(true);
        } else if (typeValue === 'Repair') {
            setShowCollectionTime(true);
        }

    }, [typeValue]);

    const handleConfirmYes = () => {
        if (isAdmin) {
            setShowConfirmDialog(false);

            axios.put("/api/booking/edit-status/", {
                id: recordId,
                status: confirmStatus,
            }).then((response) => {
                if (response.status === 200) {
                    if (confirmStatus === "Cancelled") {
                        setConfirmStatus("");
                        history.push(`/booking/view/${bookingId}`, {
                            bookingsState: bookingsState
                        });
                    } else {
                        setConfirmStatus("");
                        goToBookingsList();
                    }
                }
            }).catch((error) => {
            });
        } else {
            setShowConfirmDialog(false);
            cancelBooking();
        }
    }

    const handleDiscardChangesConfirmYes = () => {
        setShowChangesWarningDialog(false);
        goToBookingsList();
    };

    const handleDiscardChangesConfirmNo = () => {
        setShowChangesWarningDialog(false);
    };

    const handleConfirmNo = () => {
        if (isAdmin) {
            setConfirmStatus("");
        }
        setShowConfirmDialog(false);
    }

    const handleVehicleRegistrationChange = (e) => {
        setVehicleRegistration(e.target.value.toUpperCase());
        setVehicleRegistrationInvalid(false);
    }

    const handleVehicleModelValueChange = (e) => {
        setVehicleModelValue(e.target.value);
        setVehicleModelValueInvalid(false);
    }

    const handleCollectionDateValueChange = (value) => {
        setCollectionDateValue(value);
        setCollectionDateInvalid(false);
        if (value && !deliveryDateValue) {
            setDeliveryDateValue(value);
            setDeliveryDateInvalid(false);
        }
    }

    const handleCollectionTimeValueChange = (value) => {
        setCollectionTimeValue(value);
        setCollectionTimeInvalid(false);
    }

    const handleDeliveryDateValueChange = (value) => {
        setDeliveryDateValue(value);
        setDeliveryDateInvalid(false);
    }

    const handleDeliveryTimeValueChange = (value) => {
        setDeliveryTimeValue(value);
        setDeliveryTimeInvalid(false);
    }

    const handleReturnDateValueChange = (value) => {
        setReturnDateValue(value);
        setReturnDateInvalid(false);
    }
    const handleReturnTimeValueChange = (value) => {
        setReturnTimeValue(value);
        setReturnTimeInvalid(false);
    }

    const handleClientRefChange = (e) => {
        setClientRef(e.target.value);
    }

    const handleCollectionContactNameChange = (e) => {
        setCollectionContactName(e.target.value);
        setCollectionContactNameInvalid(false);
    }
    const handleCollectionContactMobileChange = (e) => {
        let newValue = e.target.value;
        let validationResult = validatePhoneNumber(newValue, collectionContactMobile);

        setCollectionContactMobile(validationResult.value);
        setCollectionContactMobileInvalid(!validationResult.valid);
    }
    const handleCollectionAddressChange = (e) => {
        setCollectionAddress(e.target.value);
    }
    const handleCollectionPostCodeChange = (e) => {
        setCollectionPostCode(e.target.value);
        setCollectionPostCodeInvalid(false);
    }
    const handleDeliveryContactNameChange = (e) => {
        setDeliveryContactName(e.target.value);
        setDeliveryContactNameInvalid(false);
    }
    const handleDeliveryContactMobileChange = (e) => {
        let newValue = e.target.value;
        let validationResult = validatePhoneNumber(newValue, deliveryContactMobile);

        setDeliveryContactMobile(validationResult.value);
        setDeliveryContactMobileInvalid(!validationResult.valid);
    }
    const handleDeliveryPostCodeChange = (e) => {
        setDeliveryPostCode(e.target.value);
        setDeliveryPostCodeInvalid(false);
    }

    const handleReturnVehicleRegistrationChange = (e) => {
        setReturnVehicleRegistration(e.target.value.toUpperCase());
        setReturnVehicleRegistrationInvalid(false);
    }
    const handleReturnVehicleModelValueChange = (e) => {
        setReturnVehicleModelValue(e.target.value);
        setReturnVehicleModelValueInvalid(false);
    }
    const handleReturnContactNameChange = (e) => {
        setReturnContactName(e.target.value);
        setReturnContactNameInvalid(false);
    }
    const handleReturnContactMobileChange = (e) => {
        let newValue = e.target.value;
        let validationResult = validatePhoneNumber(newValue, returnContactMobile);

        setReturnContactMobile(validationResult.value);
        setReturnContactMobileInvalid(!validationResult.valid);
    }
    const handleReturnPostCodeChange = (e) => {
        setReturnPostCode(e.target.value);
        setReturnPostCodeInvalid(false);
    }

    const handleCollectionAdditionalInstructionsChange = (e) => {
        setCollectionAdditionalInstructions(e.target.value);
    }
    const handleCollectionFullInspectionChange = (e) => {
        setCollectionFullInspection(e.target.checked);
    }
    const handleCollectionNearCustomerValetChange = (e) => {
        setCollectionNearCustomerValet(e.target.checked);
    }
    const handleCollectionProvideETAChange = (e) => {
        setCollectionProvideETA(e.target.checked);
    }
    const handleCollectionRequiresFuelChange = (e) => {
        setCollectionRequiresFuel(e.target.checked);
    }
    const handleCollectionIdCheckChange = (e) => {
        setCollectionIdCheck(e.target.checked);
    }
    const handleDeliveryAddressChange = (e) => {
        setDeliveryAddress(e.target.value);
    }
    const handleDeliveryAdditionalInstructionsChange = (e) => {
        setDeliveryAdditionalInstructions(e.target.value);
    }
    const handleDeliveryFullInspectionChange = (e) => {
        setDeliveryFullInspection(e.target.checked);
    }
    const handleDeliveryNearCustomerValetChange = (e) => {
        setDeliveryNearCustomerValet(e.target.checked);
    }
    const handleDeliveryProvideETAChange = (e) => {
        setDeliveryProvideETA(e.target.checked);
    }
    const handleDeliveryRequiresFuelChange = (e) => {
        setDeliveryRequiresFuel(e.target.checked);
    }
    const handleDeliveryIdCheckChange = (e) => {
        setDeliveryIdCheck(e.target.checked);
    }

    const handleReturnAddressChange = (e) => {
        setReturnAddress(e.target.value);
    }
    const handleReturnAdditionalInstructionsChange = (e) => {
        setReturnAdditionalInstructions(e.target.value);
    }
    const handleReturnFullInspectionChange = (e) => {
        setReturnFullInspection(e.target.checked);
    }
    const handleReturnNearCustomerValetChange = (e) => {
        setReturnNearCustomerValet(e.target.checked);
    }
    const handleReturnProvideETAChange = (e) => {
        setReturnProvideETA(e.target.checked);
    }
    const handleReturnRequiresFuelChange = (e) => {
        setReturnRequiresFuel(e.target.checked);
    }
    const handleReturnIdCheckChange = (e) => {
        setReturnIdCheck(e.target.checked);
    }

    const goToBookingsList = () => {
        history.push('/bookings/', {
            bookingsState: bookingsState
        });
    };

    const handleCancelAddBooking = () => {
        goToBookingsList();
    }

    const detectChanges = () => {
        if (!originalRecord) {
            return false;
        }

        let mainFields = {
            "status": status,
            "client_ref": clientRef,
            "vehicle_registration": vehicleRegistration,
            "vehicle_model": vehicleModelValue
        };

        for (let field in mainFields) {
            if (originalRecord[field] !== mainFields[field]) {
                return true;
            }
        }

        if (originalRecord["collection"]) {
            let originalDate = truncateSeconds(new Date(originalRecord["collection"]["action_date"]));

            let collectionDate = truncateDate(new Date(collectionDateValue));
            if (showCollectionTime) {
                collectionDate = addTimeValues(collectionDate, new Date(collectionTimeValue));
            } else {
                originalDate = truncateDate(originalDate)
            }

            if (originalDate.getTime() !== collectionDate.getTime()) {
                return true;
            }
            let collectionFields = {
                "contact_name": collectionContactName,
                "contact_mobile": collectionContactMobile,
                "address": collectionAddress,
                "post_code": collectionPostCode,
                "additional_instructions": collectionAdditionalInstructions,
                "full_inspection": collectionFullInspection,
                "near_customer_valet": collectionNearCustomerValet,
                "provide_eta": collectionProvideETA,
                "requires_fuel": collectionRequiresFuel,
                "id_check": collectionIdCheck
            };

            for (let field in collectionFields) {
                let value = originalRecord["collection"][field];
                if (value === null) {
                    value = "";
                }

                let value2 = collectionFields[field];
                if (value2 === null) {
                    value2 = "";
                }

                if (value !== value2) {
                    return true;
                }
            }
        }

        if (originalRecord["delivery"]) {
            let originalDate = truncateSeconds(new Date(originalRecord["delivery"]["action_date"]));

            let deliveryDate = truncateDate(new Date(deliveryDateValue));
            if (showDeliveryTime) {
                deliveryDate = addTimeValues(deliveryDate, new Date(deliveryTimeValue));
            } else {
                originalDate = truncateDate(originalDate)
            }

            if (originalDate.getTime() !== deliveryDate.getTime()) {
                return true;
            }

            let deliveryFields = {
                "contact_name": deliveryContactName,
                "contact_mobile": deliveryContactMobile,
                "address": deliveryAddress,
                "post_code": deliveryPostCode,
                "additional_instructions": deliveryAdditionalInstructions,
                "full_inspection": deliveryFullInspection,
                "near_customer_valet": deliveryNearCustomerValet,
                "provide_eta": deliveryProvideETA,
                "requires_fuel": deliveryRequiresFuel,
                "id_check": deliveryIdCheck
            };

            for (let field in deliveryFields) {
                let value = originalRecord["delivery"][field];
                if (value === null) {
                    value = "";
                }

                let value2 = deliveryFields[field];
                if (value2 === null) {
                    value2 = "";
                }

                if (value !== value2) {
                    return true;
                }
            }
        }

        if (typeValue === 'Update/Flip' && originalRecord["return"]) {
            let originalDate = truncateSeconds(new Date(originalRecord["return"]["action_date"]));

            let returnDate = truncateDate(new Date(returnDateValue));
            if (showReturnTime) {
                returnDate = addTimeValues(returnDate, new Date(returnTimeValue));
            } else {
                originalDate = truncateDate(originalDate);
            }

            if (originalDate.getTime() !== returnDate.getTime()) {
                return true;
            }

            let returnFields = {
                "vehicle_registration": returnVehicleRegistration,
                "vehicle_model": returnVehicleModelValue,
                "contact_name": returnContactName,
                "contact_mobile": returnContactMobile,
                "address": returnAddress,
                "post_code": returnPostCode,
                "additional_instructions": returnAdditionalInstructions,
                "full_inspection": returnFullInspection,
                "near_customer_valet": returnNearCustomerValet,
                "provide_eta": returnProvideETA,
                "requires_fuel": returnRequiresFuel,
                "id_check": returnIdCheck
            };

            for (let field in returnFields) {
                let value = originalRecord["return"][field];
                if (value === null) {
                    value = "";
                }

                let value2 = returnFields[field];
                if (value2 === null) {
                    value2 = "";
                }

                if (value !== value2) {
                    return true;
                }
            }
        }

        return false;
    };

    const handleGoBack = () => {
        if (detectChanges()) {
            setShowChangesWarningDialog(true);
        } else {
            goToBookingsList();
        }
    }

    const cancelBooking = () => {
        axios.put("/api/booking/", {
            id: recordId
        }).then((response) => {
            if (response.status === 200) {
                history.push(`/booking/view/${bookingId}`, {
                    bookingsState: bookingsState
                });
            }
        }).catch((error) => {
        });
    };

    const statusUpdateHandler = (newStatus) => {
        return () => {
            if (status === newStatus) return;

            setConfirmStatus(newStatus);
            if (newStatus === "Cancelled") {
                setCancelRate("");
                axios.get("/api/booking/cancel-rate/", {
                    params: {
                        id: recordId
                    }
                }).then((response) => {
                    if (response.status === 200) {
                        setCancelRate(response.data);
                        setShowConfirmDialog(true);
                    }
                }).catch((error) => {
                    setShowConfirmDialog(true);
                });
            } else {
                setShowConfirmDialog(true);
            }
        }
    };

    const saveBooking = () => {
        let valid = true;
        if (!typeValue) {
            setTypeValueInvalid(true);
            valid = false;
        }
        if (!vehicleRegistration) {
            setVehicleRegistrationInvalid(true);
            valid = false;
        }
        if (!vehicleModelValue) {
            setVehicleModelValueInvalid(true);
            valid = false;
        }
        // collection
        if (!collectionContactName) {
            setCollectionContactNameInvalid(true);
            valid = false;
        }
        if (!collectionContactMobile || collectionContactMobile.length !== 15 || collectionContactMobileInvalid) {
            setCollectionContactMobileInvalid(true);
            valid = false;
        }
        if (!collectionPostCode) {
            setCollectionPostCodeInvalid(true);
            valid = false;
        }
        if (!collectionDateValue) {
            setCollectionDateInvalid(true);
            valid = false;
        }
        if (showCollectionTime && !collectionTimeValue) {
            setCollectionTimeInvalid(true);
            valid = false;
        }
        // delivery
        if (!deliveryContactName) {
            setDeliveryContactNameInvalid(true);
            valid = false;
        }
        if (!deliveryContactMobile || deliveryContactMobile.length !== 15 || deliveryContactMobileInvalid) {
            setDeliveryContactMobileInvalid(true);
            valid = false;
        }
        if (!deliveryPostCode) {
            setDeliveryPostCodeInvalid(true);
            valid = false;
        }
        if (!deliveryDateValue) {
            setDeliveryDateInvalid(true);
            valid = false;
        }
        if (showDeliveryTime && !deliveryTimeValue) {
            setDeliveryTimeInvalid(true);
            valid = false;
        }

        if (showReturnForm) {
            if (!returnVehicleRegistration) {
                setReturnVehicleRegistrationInvalid(true);
                valid = false;
            }
            if (!returnVehicleModelValue) {
                setReturnVehicleModelValueInvalid(true);
                valid = false;
            }
            if (!returnContactName) {
                setReturnContactNameInvalid(true);
                valid = false;
            }
            if (!returnContactMobile || returnContactMobile.length !== 15 || returnContactMobileInvalid) {
                setReturnContactMobileInvalid(true);
                valid = false;
            }
            if (!returnPostCode) {
                setReturnPostCodeInvalid(true);
                valid = false;
            }
            if (!returnDateValue) {
                setReturnDateInvalid(true);
                valid = false;
            }
            if (showReturnTime && !returnTimeValue) {
                setReturnTimeInvalid(true);
                valid = false;
            }
        }

        if (!valid) {
            return;
        }

        let collectionDate = truncateDate(new Date(collectionDateValue));
        if (showCollectionTime) {
            collectionDate = addTimeValues(collectionDate, new Date(collectionTimeValue));
        }
        let deliveryDate = truncateDate(new Date(deliveryDateValue));
        if (showDeliveryTime) {
            deliveryDate = addTimeValues(deliveryDate, new Date(deliveryTimeValue));
        }

        let formData = {
            "_id": recordId,
            "status": status,
            "type": typeValue,
            "client_ref": clientRef,
            "vehicle_registration": vehicleRegistration,
            "vehicle_model": vehicleModelValue,
            "collection": {
                "contact_name": collectionContactName,
                "contact_mobile": collectionContactMobile,
                "address": collectionAddress,
                "post_code": collectionPostCode,
                "additional_instructions": collectionAdditionalInstructions,
                "full_inspection": collectionFullInspection,
                "near_customer_valet": collectionNearCustomerValet,
                "provide_eta": collectionProvideETA,
                "requires_fuel": collectionRequiresFuel,
                "id_check": collectionIdCheck,
                "action_date": collectionDate
            },
            "delivery": {
                "contact_name": deliveryContactName,
                "contact_mobile": deliveryContactMobile,
                "address": deliveryAddress,
                "post_code": deliveryPostCode,
                "additional_instructions": deliveryAdditionalInstructions,
                "full_inspection": deliveryFullInspection,
                "near_customer_valet": deliveryNearCustomerValet,
                "provide_eta": deliveryProvideETA,
                "requires_fuel": deliveryRequiresFuel,
                "id_check": deliveryIdCheck,
                "action_date": deliveryDate
            },
            "return": null
        };

        if (typeValue === 'Update/Flip') {
            let returnDate = truncateDate(new Date(returnDateValue));
            if (showReturnTime) {
                returnDate = addTimeValues(returnDate, new Date(returnTimeValue));
            }

            formData["return"] = {
                "vehicle_registration": returnVehicleRegistration,
                "vehicle_model": returnVehicleModelValue,
                "contact_name": returnContactName,
                "contact_mobile": returnContactMobile,
                "address": returnAddress,
                "post_code": returnPostCode,
                "additional_instructions": returnAdditionalInstructions,
                "full_inspection": returnFullInspection,
                "near_customer_valet": returnNearCustomerValet,
                "provide_eta": returnProvideETA,
                "requires_fuel": returnRequiresFuel,
                "id_check": returnIdCheck,
                "action_date": returnDate
            };
        }

        if (isAdmin) {
            formData["organization_id"] = organizationValue;
            formData["organization_name"] = organizationNameValue;
        }

        axios.post("/api/booking/", formData).then((response) => {
            if (response.status === 200) {
                goToBookingsList();
            }
        }).catch(() => {
        });
    }

    const handleSaveBooking = () => {
        saveBooking();
    }

    if (!bookingId) {
        return (<>
            <AppBar pageName="edit-booking"/>
            <div className="container-fluid d-flex flex-column mt-5">
                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12} spacing={1}>
                        <LinearProgress color="primary"/>
                    </Grid>
                </Grid>
            </div>
        </>);
    }

    return (<>
            <AppBar pageName="edit-booking"/>
            <Paper className={classes.buttonsBar}>
                <Grid container spacing={1}>
                    <Grid item xs={6} className={classes.containerAlignLeft}>
                        <Tooltip title="Go Back">
                            <Button
                                color="primary"
                                variant="contained"
                                size="large"
                                onClick={handleGoBack}
                                className={classes.backButton}
                            >
                                <ChevronLeft/>
                                Back
                            </Button>
                        </Tooltip>
                    </Grid>
                    {
                        allowEditing ? <Grid item xs={6} className={classes.containerAlignRight}>
                            <Button
                                className={classes.cancelButton}
                                variant="contained"
                                size="large"
                                onClick={handleCancelAddBooking}
                            >Cancel</Button>
                            <Button
                                className={classes.saveButton}
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={handleSaveBooking}
                            >Save</Button>
                        </Grid> : <Grid item xs={6}></Grid>
                    }
                </Grid>
            </Paper>
            <div className="container-fluid d-flex flex-column">
                <Paper className="d-flex p-3 justify-content-between flex-wrap align-items-baseline">
                    <Grid container spacing={1}>
                        {
                            isAdmin && <Grid item xs={3}>
                                <TextField
                                    label="Organisation"
                                    type="text"
                                    variant="outlined"
                                    value={organizationNameValue}
                                    contentEditable={false}
                                    className={classes.field}
                                />
                            </Grid>
                        }
                        <Grid item xs={isAdmin ? 4 : 6}>
                            {
                                showCountdown && <div className={classes.countdownText}>
                                    Active In: {timeUntilActiveText}
                                </div>
                            }
                        </Grid>
                        <Grid item xs={isAdmin ? 5 : 6}>
                            <div className={classes.statusLabel}>
                                Last Edit by {modifiedBy} at {modifiedAt}
                            </div>
                        </Grid>
                        <Grid item xs={1}>
                            <div className={classes.statusLabel}>
                                Status:
                            </div>
                        </Grid>
                        <Grid item xs={11}>
                            {
                                isAdmin ? <div>
                                    <Button
                                        className={classes.statusButton}
                                        color={status === "Booked" ? "primary" : undefined}
                                        size="large"
                                        variant="contained"
                                        onClick={statusUpdateHandler("Booked")}
                                    >Booked</Button>
                                    <Button
                                        className={classes.statusButton}
                                        color={status === "Cancelled" ? "primary" : undefined}
                                        size="large"
                                        variant="contained"
                                        onClick={statusUpdateHandler("Cancelled")}
                                    >{status === "Cancelled" ? "Cancelled" : "Cancel"}</Button>
                                    <Button
                                        className={classes.statusButton}
                                        color={status === "Active" ? "primary" : undefined}
                                        size="large"
                                        variant="contained"
                                        onClick={statusUpdateHandler("Active")}
                                    >Active</Button>
                                    <Button
                                        className={classes.statusButton}
                                        color={status === "Breakdown" ? "primary" : undefined}
                                        size="large"
                                        variant="contained"
                                        onClick={statusUpdateHandler("Breakdown")}
                                    >Breakdown</Button>
                                    <Button
                                        className={classes.statusButton}
                                        color={status === "Completed" ? "primary" : undefined}
                                        size="large"
                                        variant="contained"
                                        onClick={statusUpdateHandler("Completed")}
                                    >Completed</Button>
                                    <Button
                                        className={classes.statusButton}
                                        color={status === "Not Completed" ? "primary" : undefined}
                                        size="large"
                                        variant="contained"
                                        onClick={statusUpdateHandler("Not Completed")}
                                    >Not Completed</Button>
                                </div> : <div>
                                    <Button
                                        disabled={status !== "Booked"}
                                        className={classes.statusButton}
                                        color={status === "Booked" ? "primary" : undefined}
                                        size="large"
                                        variant="contained"
                                    >Booked</Button>
                                    <Button
                                        className={classes.statusButton}
                                        disabled={status !== "Booked"}
                                        size="large"
                                        variant="contained"
                                        onClick={handleCancelStatus}
                                    >Cancel</Button>
                                    <Button
                                        disabled={status !== "Active"}
                                        className={classes.statusButton}
                                        color={status === "Active" ? "primary" : undefined}
                                        size="large"
                                        variant="contained"
                                    >Active</Button>
                                    <Button
                                        disabled={true}
                                        className={classes.statusButton}
                                        size="large"
                                        variant="contained"
                                    >Breakdown</Button>
                                    <Button
                                        disabled={true}
                                        className={classes.statusButton}
                                        size="large"
                                        variant="contained"
                                    >Completed</Button>
                                    <Button
                                        disabled={true}
                                        className={classes.statusButton}
                                        size="large"
                                        variant="contained"
                                    >Not Completed</Button>
                                </div>
                            }
                        </Grid>
                        <Grid container item xs={12} spacing={1}>&nbsp;</Grid>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item xs={2}>
                                <TextField
                                    label="Booking ID"
                                    type="text"
                                    variant="outlined"
                                    value={bookingId}
                                    contentEditable={false}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={2}>
                                <TextField
                                    error={typeValueInvalid}
                                    label="Booking Type"
                                    type="text"
                                    variant="outlined"
                                    value={typeValue}
                                    contentEditable={false}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={2}>
                                <TextField
                                    label="Client Ref"
                                    type="text"
                                    variant="outlined"
                                    value={clientRef}
                                    onChange={handleClientRefChange}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={3}>
                                <TextField
                                    error={vehicleRegistrationInvalid}
                                    label="Vehicle Registration"
                                    type="text"
                                    variant="outlined"
                                    value={vehicleRegistration}
                                    onChange={handleVehicleRegistrationChange}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={3}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel id="demo-simple-select-outlined-label-2">
                                        Vehicle Model
                                    </InputLabel>
                                    <Select
                                        error={vehicleModelValueInvalid}
                                        labelId="demo-simple-select-outlined-label-2"
                                        id="demo-simple-select-outlined-2"
                                        value={vehicleModelValue}
                                        onChange={handleVehicleModelValueChange}
                                        label="Vehicle Model"
                                    >
                                        {vehicleModels.map((v) => (
                                            <MenuItem key={v} value={v}>{v}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>&nbsp;</Grid>
                        <Grid container item xs={12}>
                            <span className={classes.sectionHeading}>Collected From</span>
                        </Grid>
                        <Grid container item xs={12}></Grid>
                        <Grid container item xs={5} spacing={2} justify="space-around">
                            <Grid container item xs={6} spacing={1}>
                                <TextField
                                    error={collectionContactNameInvalid}
                                    label="Contact Name"
                                    type="text"
                                    variant="outlined"
                                    value={collectionContactName}
                                    onChange={handleCollectionContactNameChange}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={6} spacing={1}>
                                <TextField
                                    error={collectionContactMobileInvalid}
                                    label="Contact Mobile"
                                    placeholder="Format: 44 xxxx xxx xxx"
                                    type="text"
                                    variant="outlined"
                                    value={collectionContactMobile}
                                    onChange={handleCollectionContactMobileChange}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <TextField
                                    label="Address"
                                    type="text"
                                    variant="outlined"
                                    value={collectionAddress}
                                    onChange={handleCollectionAddressChange}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <TextField
                                    error={collectionPostCodeInvalid}
                                    label="Post Code"
                                    type="text"
                                    variant="outlined"
                                    value={collectionPostCode}
                                    onChange={handleCollectionPostCodeChange}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={12} spacing={1} justify="space-between">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        className={classes.dateField}
                                        error={collectionDateInvalid}
                                        disableToolbar
                                        variant="inline"
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        label="Collection Date"
                                        value={collectionDateValue}
                                        onChange={handleCollectionDateValueChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                    {
                                        showCollectionTime && <KeyboardTimePicker
                                            className={classes.timeField}
                                            error={collectionTimeInvalid}
                                            margin="normal"
                                            ampm={false}
                                            clearable
                                            variant="inline"
                                            inputVariant="outlined"
                                            label="Collection Time"
                                            value={collectionTimeValue}
                                            onChange={handleCollectionTimeValueChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        />
                                    }
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <Grid container item xs={4} spacing={1}>
                            <TextField
                                multiline
                                rowsMax={12}
                                rows={12}
                                placeholder="Additional Instructions"
                                type="textarea"
                                variant="outlined"
                                value={collectionAdditionalInstructions}
                                onChange={handleCollectionAdditionalInstructionsChange}
                                className={classes.field}
                            />
                        </Grid>
                        <Grid container item xs={3} spacing={1}>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={collectionFullInspection}
                                                onChange={handleCollectionFullInspectionChange}
                                            />
                                        }
                                        label="Full Inspection"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={collectionNearCustomerValet}
                                                onChange={handleCollectionNearCustomerValetChange}
                                            />
                                        }
                                        label="Near-Customer Valet"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={collectionProvideETA}
                                                onChange={handleCollectionProvideETAChange}
                                            />
                                        }
                                        label="Driver to provide ETA"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={collectionRequiresFuel}
                                                onChange={handleCollectionRequiresFuelChange}
                                            />
                                        }
                                        label="Requires Fuel"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={collectionIdCheck}
                                                onChange={handleCollectionIdCheckChange}
                                            />
                                        }
                                        label="ID check"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12}>&nbsp;</Grid>
                    <Grid container item xs={12}>&nbsp;</Grid>
                    <div className={classes.separatorLineContainer}>
                        <hr className={classes.separatorLine}/>
                    </div>
                    <Grid container item xs={12}>&nbsp;</Grid>

                    <Grid container spacing={1}>
                        <Grid container item xs={12}>
                            <span className={classes.sectionHeading}>Delivered To</span>
                        </Grid>
                        <Grid container item xs={12}></Grid>
                        <Grid container item xs={5} spacing={2} justify="space-around">
                            <Grid container item xs={6} spacing={1}>
                                <TextField
                                    error={deliveryContactNameInvalid}
                                    label="Contact Name"
                                    type="text"
                                    variant="outlined"
                                    value={deliveryContactName}
                                    onChange={handleDeliveryContactNameChange}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={6} spacing={1}>
                                <TextField
                                    error={deliveryContactMobileInvalid}
                                    label="Contact Mobile"
                                    placeholder="Format: 44 xxxx xxx xxx"
                                    type="text"
                                    variant="outlined"
                                    value={deliveryContactMobile}
                                    onChange={handleDeliveryContactMobileChange}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <TextField
                                    label="Address"
                                    type="text"
                                    variant="outlined"
                                    value={deliveryAddress}
                                    onChange={handleDeliveryAddressChange}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <TextField
                                    error={deliveryPostCodeInvalid}
                                    label="Post Code"
                                    type="text"
                                    variant="outlined"
                                    value={deliveryPostCode}
                                    onChange={handleDeliveryPostCodeChange}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={12} spacing={1} justify="space-between">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        className={classes.dateField}
                                        error={deliveryDateInvalid}
                                        disableToolbar
                                        variant="inline"
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        label="Delivery Date"
                                        value={deliveryDateValue}
                                        onChange={handleDeliveryDateValueChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                    {
                                        showDeliveryTime && <KeyboardTimePicker
                                            className={classes.timeField}
                                            error={deliveryTimeInvalid}
                                            margin="normal"
                                            ampm={false}
                                            clearable
                                            variant="inline"
                                            inputVariant="outlined"
                                            label="Delivery Time"
                                            value={deliveryTimeValue}
                                            onChange={handleDeliveryTimeValueChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        />
                                    }
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <Grid container item xs={4} spacing={1}>
                            <TextField
                                multiline
                                rowsMax={12}
                                rows={12}
                                placeholder="Additional Instructions"
                                type="textarea"
                                variant="outlined"
                                value={deliveryAdditionalInstructions}
                                onChange={handleDeliveryAdditionalInstructionsChange}
                                className={classes.field}
                            />
                        </Grid>
                        <Grid container item xs={3} spacing={1}>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={deliveryFullInspection}
                                                onChange={handleDeliveryFullInspectionChange}
                                            />
                                        }
                                        label="Full Inspection"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={deliveryNearCustomerValet}
                                                onChange={handleDeliveryNearCustomerValetChange}
                                            />
                                        }
                                        label="Near-Customer Valet"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={deliveryProvideETA}
                                                onChange={handleDeliveryProvideETAChange}
                                            />
                                        }
                                        label="Driver to provide ETA"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={deliveryRequiresFuel}
                                                onChange={handleDeliveryRequiresFuelChange}
                                            />
                                        }
                                        label="Requires Fuel"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={deliveryIdCheck}
                                                onChange={handleDeliveryIdCheckChange}
                                            />
                                        }
                                        label="ID check"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        showReturnForm && <>
                            <Grid container item xs={12}>&nbsp;</Grid>
                            <Grid container item xs={12}>&nbsp;</Grid>
                            <div className={classes.separatorLineContainer}>
                                <hr className={classes.separatorLine}/>
                            </div>
                            <Grid container item xs={12}>&nbsp;</Grid>

                            <Grid container spacing={1}>
                                <Grid container item xs={5}></Grid>
                                <Grid container item xs={3}>
                                    <TextField
                                        error={returnVehicleRegistrationInvalid}
                                        label="Vehicle Registration"
                                        type="text"
                                        autoComplete="current-password"
                                        variant="outlined"
                                        value={returnVehicleRegistration}
                                        onChange={handleReturnVehicleRegistrationChange}
                                        className={classes.field}
                                    />
                                </Grid>
                                <Grid container item xs={3}>
                                    <FormControl
                                        variant="outlined"
                                        className={classes.formControl}
                                        fullWidth
                                    >
                                        <InputLabel id="demo-simple-select-outlined-label-ret">
                                            Vehicle Model
                                        </InputLabel>
                                        <Select
                                            error={returnVehicleModelValueInvalid}
                                            labelId="demo-simple-select-outlined-label-ret"
                                            value={returnVehicleModelValue}
                                            onChange={handleReturnVehicleModelValueChange}
                                            label="Vehicle Model"
                                        >
                                            {vehicleModels.map((v) => (
                                                <MenuItem key={v} value={v}>{v}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={1}></Grid>
                                <Grid container item xs={12}>
                                    <span className={classes.sectionHeading}>Return Vehicle To</span>
                                </Grid>
                                <Grid container item xs={12}></Grid>
                                <Grid container item xs={5} spacing={2} justify="space-around">
                                    <Grid container item xs={6} spacing={1}>
                                        <TextField
                                            error={returnContactNameInvalid}
                                            label="Contact Name"
                                            type="text"
                                            variant="outlined"
                                            value={returnContactName}
                                            onChange={handleReturnContactNameChange}
                                            className={classes.field}
                                        />
                                    </Grid>
                                    <Grid container item xs={6} spacing={1}>
                                        <TextField
                                            error={returnContactMobileInvalid}
                                            label="Contact Mobile"
                                            placeholder="Format: 44 xxxx xxx xxx"
                                            type="text"
                                            variant="outlined"
                                            value={returnContactMobile}
                                            onChange={handleReturnContactMobileChange}
                                            className={classes.field}
                                        />
                                    </Grid>
                                    <Grid container item xs={12} spacing={1}>
                                        <TextField
                                            label="Address"
                                            type="text"
                                            variant="outlined"
                                            value={returnAddress}
                                            onChange={handleReturnAddressChange}
                                            className={classes.field}
                                        />
                                    </Grid>
                                    <Grid container item xs={12} spacing={1}>
                                        <TextField
                                            error={returnPostCodeInvalid}
                                            label="Post Code"
                                            type="text"
                                            variant="outlined"
                                            value={returnPostCode}
                                            onChange={handleReturnPostCodeChange}
                                            className={classes.field}
                                        />
                                    </Grid>
                                    <Grid container item xs={12} spacing={1} justify="space-between">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                className={classes.dateField}
                                                error={returnDateInvalid}
                                                disableToolbar
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                label="Return Date"
                                                value={returnDateValue}
                                                onChange={handleReturnDateValueChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                            {
                                                showReturnTime && <KeyboardTimePicker
                                                    className={classes.timeField}
                                                    error={returnTimeInvalid}
                                                    margin="normal"
                                                    ampm={false}
                                                    clearable
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    label="Return Time"
                                                    value={returnTimeValue}
                                                    onChange={handleReturnTimeValueChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                />
                                            }
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={4} spacing={1}>
                                    <TextField
                                        multiline
                                        rowsMax={12}
                                        rows={12}
                                        placeholder="Additional Instructions"
                                        type="textarea"
                                        variant="outlined"
                                        value={returnAdditionalInstructions}
                                        onChange={handleReturnAdditionalInstructionsChange}
                                        className={classes.field}
                                    />
                                </Grid>
                                <Grid container item xs={3} spacing={1}>
                                    <Grid container item xs={12} spacing={1}>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormControlLabel
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        color="secondary"
                                                        checked={returnFullInspection}
                                                        onChange={handleReturnFullInspectionChange}
                                                    />
                                                }
                                                label="Full Inspection"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={12} spacing={1}>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormControlLabel
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        color="secondary"
                                                        checked={returnNearCustomerValet}
                                                        onChange={handleReturnNearCustomerValetChange}
                                                    />
                                                }
                                                label="Near-Customer Valet"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={12} spacing={1}>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormControlLabel
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        color="secondary"
                                                        checked={returnProvideETA}
                                                        onChange={handleReturnProvideETAChange}
                                                    />
                                                }
                                                label="Driver to provide ETA"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={12} spacing={1}>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormControlLabel
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        color="secondary"
                                                        checked={returnRequiresFuel}
                                                        onChange={handleReturnRequiresFuelChange}
                                                    />
                                                }
                                                label="Requires Fuel"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={12} spacing={1}>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormControlLabel
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        color="secondary"
                                                        checked={returnIdCheck}
                                                        onChange={handleReturnIdCheckChange}
                                                    />
                                                }
                                                label="ID check"
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    }
                    <Grid container item xs={12}>&nbsp;</Grid>
                    <Grid container item xs={12}>&nbsp;</Grid>
                    <div className={classes.separatorLineContainer}>
                        <hr className={classes.separatorLine}/>
                    </div>
                    <Grid container item xs={12}>&nbsp;</Grid>
                    {
                        showConfirmDialog && (isAdmin ? <Dialog
                            fullWidth={true}
                            open={true}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Confirm change booking status</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to change booking status to <b>{confirmStatus}</b>?
                                    {
                                        confirmStatus === "Cancelled" ? (cancelRate ? <p>Cancellation Charge: {cancelRate}.</p> : null) : null
                                    }
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleConfirmYes} color="primary" variant="contained">
                                    Yes
                                </Button>
                                <Button onClick={handleConfirmNo} variant="contained">
                                    No
                                </Button>
                            </DialogActions>
                        </Dialog> : <Dialog
                            fullWidth={true}
                            open={true}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Confirm canceling booking</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to cancel booking?
                                    {
                                        cancelRate ? <p>Cancellation Charge: {cancelRate}.</p> : null
                                    }
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleConfirmYes} color="primary" variant="contained">
                                    Yes
                                </Button>
                                <Button onClick={handleConfirmNo} variant="contained">
                                    No
                                </Button>
                            </DialogActions>
                        </Dialog>)
                    }
                    {
                        showChangesWarningDialog && <Dialog
                            fullWidth={true}
                            open={true}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Changes detected. Are you sure you want to discard changes and go back?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleDiscardChangesConfirmYes} color="primary" variant="contained">
                                    Yes
                                </Button>
                                <Button onClick={handleDiscardChangesConfirmNo} variant="contained">
                                    No
                                </Button>
                            </DialogActions>
                        </Dialog>
                    }
                </Paper>
            </div>
        </>
    );
}
