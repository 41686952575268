import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
    field: {
        width: '100%'
    },
});

export default function AddOrganizatinDialog({onSave, onCancel}) {
    const classes = useStyles();

    const [orgName, setOrgName] = useState("");
    const [orgCode, setOrgCode] = useState("");

    const [orgNameInvalid, setOrgNameInvalid] = useState(false);
    const [orgCodeInvalid, setOrgCodeInvalid] = useState(false);

    const handleOrgNameChange = (e) => {
        setOrgName(e.target.value);
        setOrgNameInvalid(false);
    };

    const handleOrgCodeChange = (e) => {
        let value = e.target.value;
        if (value.length <= 3) {
            setOrgCode(value.toUpperCase());
        }
        setOrgCodeInvalid(false);
    };

    const handleSave = () => {
        let valid = true;

        if (!orgName) {
            setOrgNameInvalid(true);
            valid = false;
        }

        if (!orgCode) {
            setOrgCodeInvalid(true);
            valid = false;
        }

        if (!valid) return;

        onSave({
            name: orgName,
            code: orgCode
        });
    };
    const handleCancel = () => {
        onCancel();
    };

    return (
        <Dialog
            open={true}
            fullWidth={true}
        >
            <DialogTitle>Create Organisation</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <TextField
                                error={orgNameInvalid}
                                label="Name"
                                type="text"
                                variant="outlined"
                                value={orgName}
                                onChange={handleOrgNameChange}
                                className={classes.field}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={orgCodeInvalid}
                                label="Code"
                                type="text"
                                variant="outlined"
                                value={orgCode}
                                onChange={handleOrgCodeChange}
                                className={classes.field}
                            />
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSave}
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
