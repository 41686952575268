import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import {Redirect} from "react-router-dom";
import AppBar from "../../components/Header";
import {UserContext} from "../../contexts/user-profile";
import Table from "@material-ui/core/Table";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {AddCircle, RemoveCircle} from "@material-ui/icons";
import {Tooltip} from "@material-ui/core";
import AddUserDialog from "./Dialog/AddUser";
import AddOrganizatinDialog from "./Dialog/AddOrganization";

const useStyles = makeStyles({
    paper: {
        height: "800px",
        marginTop: 25,
        padding: 20,
    },
    table: {
        width: '100%'
    },
    tableRow: {
        cursor: 'pointer',
    },
    columnName: {
        fontWeight: 'bold',
        fontSize: '14px'
    },
    actionColumnName: {
        width: '50px'
    },
    actionColumnRemove: {
        color: 'maroon',
        cursor: 'pointer'
    },
    actionColumnAdd: {
        color: 'darkgreen',
        cursor: 'pointer'
    },
    headingMain: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '10px'
    },
    headingDescription: {
        fontSize: '12px',
        fontWeight: 'normal'
    }
});

export default function UserManagementPage() {
    const classes = useStyles();

    const [selectedOrg, setSelectedOrg] = useState(null);

    const [organizations, setOrganizations] = useState([]);
    const [users, setUsers] = useState([]);
    const [newUsers, setNewUsers] = useState([]);

    const [showAddUserDialog, setShowAddUserDialog] = useState(false);
    const [showAddOrgDialog, setShowAddOrgDialog] = useState(false);

    const {isAdmin} = useContext(UserContext);

    const handleAddUser = () => {
        setShowAddUserDialog(true);
    };

    const getOrganizations = async () => {
        setSelectedOrg(null);
        axios.get("/api/orgs/").then((response) => {
            if (response.status === 200) {
                setOrganizations(response.data);
            }
        }).catch((err) => {
        });
    };

    const createOrganization = (orgName, orgCode, callback) => {
        axios.post("/api/orgs/", {
            name: orgName,
            code: orgCode
        }).then((response) => {
            if (response.status === 200) {
                getOrganizations();
                getNewUsers();
                callback();
            }
        }).catch((err) => {
        });
    };


    const getNewUsers = async () => {
        axios.get("/api/users/").then((response) => {
            if (response.status === 200) {
                setNewUsers(response.data);
            }
        }).catch((err) => {
        });
    };

    const getUsers = async (org) => {
        axios.get("/api/orgs/user/", {
            params: {
                org: org
            }
        }).then((response) => {
            if (response.status === 200) {
                setUsers(response.data);
            }
        }).catch((err) => {
            setUsers([]);
        });
    };

    const addUserToOrg = (orgCode, userEmail, callback) => {
        axios.post("/api/orgs/user/", {
            org: orgCode,
            email: userEmail
        }).then((response) => {
            if (response.status === 200) {
                getUsers(orgCode);
                getNewUsers();
                callback();
            }
        }).catch((err) => {
        });
    };

    const removeUserFromOrg = (orgCode, userEmail, callback) => {
        axios.delete("/api/orgs/user/", {
            params: {
                org: orgCode,
                email: userEmail
            }
        }).then((response) => {
            if (response.status === 200) {
                getUsers(orgCode);
                getNewUsers();
                callback();
            }
        }).catch((err) => {
        });
    };

    useEffect(() => {
        getOrganizations();
        getNewUsers();
    }, []);

    useEffect(() => {
        if (selectedOrg) {
            getUsers(selectedOrg.code);
        } else {
            setUsers([]);
        }
    }, [selectedOrg]);

    if (!isAdmin) {
        return <Redirect to="/bookings"/>;
    }

    return (
        <>
            <AppBar pageName="users"/>
            <Grid container spacing={0}>
                <Grid item xs={4}>
                    <Paper className={classes.paper}>
                        <div className={classes.headingMain}>Organizations</div>
                        <Button
                            color="darkblue"
                            variant="contained"
                            onClick={()=>{
                                getOrganizations();
                            }}
                        >Reload</Button>
                        &nbsp;
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={()=>{
                                setShowAddOrgDialog(true);
                            }}
                        >Create</Button>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.columnName} align="left">Name</TableCell>
                                    <TableCell className={classes.columnName} align="left">Code</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {organizations.map((org) => (
                                    <TableRow
                                        className={classes.tableRow}
                                        key={org.code}
                                        hover={true}
                                        selected={selectedOrg === org}
                                        onClick={() => {
                                            setSelectedOrg(org);
                                        }}
                                    >
                                        <TableCell align="left">{org.name}</TableCell>
                                        <TableCell align="left">{org.code}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper className={classes.paper}>
                        <div className={classes.headingMain}>Organisation Users<span
                            className={classes.headingDescription}>{selectedOrg ? " - " + selectedOrg.name : ""}</span>
                        </div>
                        <Button
                            disabled={!selectedOrg}
                            color="primary"
                            variant="contained"
                            onClick={handleAddUser}
                        >Add User</Button>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.columnName} align="left">Email</TableCell>
                                    {
                                        selectedOrg && <TableCell className={classes.actionColumnName} align="center">Remove</TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user) => (
                                    <TableRow
                                        key={user.email}
                                        hover={true}
                                    >
                                        <TableCell align="left">{user.email}</TableCell>
                                        <TableCell align="center" className={classes.actionColumnRemove}>
                                            <Tooltip title="Remove">
                                                <RemoveCircle
                                                    onClick={() => {
                                                        removeUserFromOrg(selectedOrg.code, user.email);
                                                    }}
                                                />
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper className={classes.paper}>
                        <div className={classes.headingMain}>New Users</div>
                        <div className={classes.headingDescription}>(Without Organisation)
                            <Button disabled={true}>&nbsp;</Button>
                        </div>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    {
                                        selectedOrg && <TableCell className={classes.actionColumnName} align="center">Add</TableCell>
                                    }
                                    <TableCell className={classes.columnName} align="left">Email</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {newUsers.map((user) => (
                                    <TableRow
                                        key={user.email}
                                        hover={true}
                                    >
                                        {
                                            selectedOrg && <TableCell align="center" className={classes.actionColumnAdd}>
                                                <Tooltip title="Add">
                                                    <AddCircle
                                                        onClick={() => {
                                                            addUserToOrg(selectedOrg.code, user.email);
                                                        }}
                                                    />
                                                </Tooltip>
                                            </TableCell>
                                        }
                                        <TableCell align="left">{user.email}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </Grid>
            {
                showAddUserDialog && <AddUserDialog
                    users={newUsers}
                    onCancel={() => {
                        setShowAddUserDialog(false);
                    }}
                    onSave={(data) => {
                        addUserToOrg(selectedOrg.code, data.email, () => {
                            setShowAddUserDialog(false);
                        });
                    }}
                />
            }
            {
                showAddOrgDialog && <AddOrganizatinDialog
                    onCancel={() => {
                        setShowAddOrgDialog(false);
                    }}
                    onSave={(data) => {
                        createOrganization(data.name, data.code, () => {
                            setShowAddOrgDialog(false);
                        });
                    }}
                />
            }
        </>
    );
}
