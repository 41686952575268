import React from "react";
import Paper from "@material-ui/core/Paper";
import AppBar from "../../components/Header";
import {createStyles, makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
    createStyles({
        link: {
            color: '#0a8bcc'
        },
        header: {
            fontSize: '18px',
            padding: '10px 10px'
        },
        row: {
            padding: '5px 10px'
        },
        footer: {
            padding: '5px 10px'
        }
    })
);

export default function SupportPage() {
    const classes = useStyles();

    return (
        <>
            <AppBar pageName="support"/>
            <div className="container-fluid d-flex flex-column mt-5">
                <Paper className="d-flex p-3 justify-content-between flex-wrap">
                    <div className="px-1 col-12 col-lg-12">
                        <div className={classes.header}>Contact Drive Ferris</div>
                        <div className={classes.row}>Tel: 0207 175 2500</div>
                        <div className={classes.row}>Email: <a className={classes.link} href="mailto://operations@driveferris.com">operations@driveferris.com</a></div>
                        <div className={classes.footer}>Hours of Operation: Mon-Fri 08:00-20:00</div>
                    </div>
                </Paper>
            </div>
        </>
    );
}
