import React, {useContext, useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import AppBar from "../../components/Header";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {Button, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {ArrowDownward, PlaylistAdd} from "@material-ui/icons";
import axios from "axios";
import ImportResult from "../ImportResult";
import {UserContext} from "../../contexts/user-profile";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) =>
    createStyles({
        header: {
            fontSize: '18px',
            padding: '10px 10px'
        },
        row: {
            padding: '5px 10px'
        },
        successMessage: {
            color: 'green',
            padding: '5px 10px',
            width: '100%',
            textAlign: 'center'
        },
        failMessage: {
            color: 'goldenrod',
            padding: '5px 10px',
            width: '100%',
            textAlign: 'center'
        },
        errorMessage: {
            color: 'red',
            padding: '5px 10px',
            width: '100%',
            textAlign: 'center'
        },
        fileInput: {
            display: "none"
        },
        formControl: {
            margin: "0px 10px",
            width: '100%',
        },
        button: {
            marginLeft: '10px',
            marginRight: '10px'
        },
        alignLeft: {
            width: '100%',
            textAlign: 'left'
        },
        alignRight: {
            width: '100%',
            textAlign: 'right'
        }
    })
);

export default function ImportPage() {
    const classes = useStyles();

    const { isAdmin } = useContext(UserContext);

    const [organizations, setOrganizations] = useState([]);
    const [organizationValue, setOrganizationValue] = useState("");

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const [addedCount, setAddedCount] = useState(0);
    const [updatedCount, setUpdatedCount] = useState(0);
    const [skippedCount, setSkippedCount] = useState(0);

    const [importResultAdded, setImportResultAdded] = useState([]);
    const [importResultUpdated, setImportResultUpdated] = useState([]);
    const [importResultSkipped, setImportResultSkipped] = useState([]);

    const [showImportResult, setShowImportResult] = useState(false);

    const handleOrgValueChange = (e) => {
        setOrganizationValue(e.target.value);
    };

    const handleFileChange = (e) => {
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            let formData = new FormData();
            formData.append('file', selectedFile);
            if (isAdmin) {
                formData.append('org', organizationValue);
            }
            setShowImportResult(false);
            setImportResultAdded([]);
            setImportResultUpdated([]);
            setImportResultSkipped([]);
            setShowSuccessMessage(false);
            setAddedCount(0);
            setUpdatedCount(0);
            setSkippedCount(0);
            axios.post("/api/booking/import/", formData).then((res) => {
                if (res.status === 200) {
                    setShowErrorMessage(false);
                    setShowSuccessMessage(true);
                    if (res.data) {
                        setShowImportResult(true);

                        setImportResultAdded(res.data.added);
                        setAddedCount(res.data.added.length);

                        setImportResultUpdated(res.data.updated);
                        setUpdatedCount(res.data.updated.length);

                        setImportResultSkipped(res.data.skipped);
                        setSkippedCount(res.data.skipped.length);
                    }
                } else {
                    setShowSuccessMessage(false);
                    setShowErrorMessage(true);
                    setTimeout(() => {
                        setShowErrorMessage(false);
                    }, 3000);
                }
            }).catch(() => {
                setShowSuccessMessage(false);
                setShowErrorMessage(true);
                setTimeout(() => {
                    setShowErrorMessage(false);
                }, 3000);
            });
        }
    };

    useEffect(() => {
        const getOrganizations = () => {
            axios.get("/api/orgs/").then((response) => {
                if (response.status === 200) {
                    setOrganizations(response.data);
                }
            }).catch((err) => {
            });
        };

        if (isAdmin) {
            getOrganizations();
        }
    }, [isAdmin]);

    return (
        <>
            <AppBar pageName="import"/>
            <div className="container-fluid d-flex flex-column mt-5">
                <Paper className="d-flex p-3 justify-content-between flex-wrap">
                    <div className="px-1 col-12 col-lg-12">
                        <div className={classes.header}>Import</div>
                        <div className={classes.row}>&nbsp;</div>
                        {
                            isAdmin ?
                            (organizationValue ? null : <div className={classes.row}>Select organisation:</div>) : null
                        }
                        {
                            isAdmin && <Grid container item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                >
                                    <InputLabel id="demo-simple-select-outlined-label-org">Organisation</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label-org"
                                        id="demo-simple-select-outlined-org"
                                        value={organizationValue}
                                        onChange={handleOrgValueChange}
                                        label="Organisation"
                                    >
                                        {
                                            organizations.map((org) => (
                                                <MenuItem key={org.code} value={org.code}>{org.name}&nbsp;
                                                    <b>{org.code}</b></MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <div>&nbsp;</div>
                            </Grid>
                        }
                        <div className={classes.row}>&nbsp;</div>
                        {
                            (!isAdmin || organizationValue) && <Grid container spacing={1}>
                                <Grid item xs={6} className={classes.alignLeft}>
                                    <div className={classes.row}>Upload CSV or Excel file:</div>
                                    <Button
                                        className={classes.button}
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        component="label"
                                        startIcon={<PlaylistAdd/>}
                                    >
                                        Upload
                                        <input
                                            type="file"
                                            className={classes.fileInput}
                                            onChange={handleFileChange}
                                            accept=".csv, .xls, .xlsx"
                                        />
                                    </Button>
                                </Grid>
                                <Grid item xs={6} className={classes.alignRight}>
                                    <div className={classes.row}>Download Excel file template:</div>
                                    <Button
                                        className={classes.button}
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        component="label"
                                        startIcon={<ArrowDownward/>}
                                        onClick={() => {
                                            const link = document.createElement('a');
                                            link.href = "/templates/template.xlsx";
                                            link.setAttribute('download', 'template.xlsx');
                                            document.body.appendChild(link);
                                            link.click();
                                        }}
                                    >
                                        Download
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    </div>
                    <div className="px-1 col-12 col-lg-12">
                        {
                            showErrorMessage && <div className={classes.errorMessage}>
                                Error occurred while importing data
                            </div>
                        }
                        {
                            (showSuccessMessage && addedCount > 0) && <div className={classes.successMessage}>
                                {addedCount} record(s) added successfully.
                            </div>
                        }
                        {
                            (showSuccessMessage && updatedCount > 0) && <div className={classes.successMessage}>
                                {updatedCount} record(s) updated.
                            </div>
                        }
                        {
                            (showSuccessMessage && skippedCount > 0) && <div className={classes.failMessage}>
                                {skippedCount} record(s) skipped.
                            </div>
                        }
                    </div>
                </Paper>
                {
                    (showImportResult && addedCount > 0) && <ImportResult success={true} title={"Added records"} bookings={importResultAdded}/>
                }
                {
                    (showImportResult && updatedCount > 0)  && <ImportResult success={true} title={"Updated records"} bookings={importResultUpdated}/>
                }
                {
                    (showImportResult && skippedCount > 0) && <ImportResult title={"Skipped records"} bookings={importResultSkipped}/>
                }
            </div>
        </>
    );
}
