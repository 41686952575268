import React, {useContext} from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {UserContext} from "../../contexts/user-profile";
import {Avatar} from "@material-ui/core";
import {NavLink} from 'react-router-dom';
import styles from "./css/logo.module.css";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: "sticky",
            top: 0,
            zIndex: 100,
        },
        toolbar: {
            backgroundColor: "#d8eaeb",
            display: "flex",
            justifyContent: "space-between",
        },
        title: {
            display: "none",
            [theme.breakpoints.up("sm")]: {
                display: "block",
            },
            cursor: "pointer",
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        selectedMenuItem: {
            fontWeight: 'bold',
            color: '#1D8A8B',
            fontSize: '16px'
        },
        menuItem: {
            cursor: "pointer",
            color: "#1D8A8B"
        },
        navLink: {
            padding: '20px 5px'
        },
        avatar: {
            marginLeft: 20
        },
        signOutButton: {
            marginLeft: 20,
            textTransform: 'none'
        },
        userInfo: {
            maxWidth: '200px',
            textAlign: 'right'
        },
        userName: {
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        orgName: {
            fontSize: '12px',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    })
);

const CustomAppBar = ({pageName}) => {
    const classes = useStyles();
    const {name, picture, organizationId, organizationName, isAdmin} = useContext(UserContext);

    const imageAlt = name ? name.substring(0, 1) : "User";

    let link_bookings = pageName === 'bookings' ? '#' : '/bookings';
    if (pageName === 'edit-booking' || pageName === 'view-booking') {
        link_bookings = '#';
    }
    const link_add_booking = pageName === 'add-booking' ? '#' : '/booking/add';
    const link_import = pageName === 'import' ? '#' : '/import';
    const link_users = pageName === 'users' ? '#' : '/users';
    const link_support = pageName === 'support' ? '#' : '/support';
    const link_settings = pageName === 'settings' ? '#' : '/settings';

    let styles_bookings = pageName === 'bookings' ? classes.selectedMenuItem : classes.menuItem;
    if (pageName === 'edit-booking' || pageName === 'view-booking') {
        styles_bookings = classes.selectedMenuItem;
    }
    const styles_add_booking = pageName === 'add-booking' ? classes.selectedMenuItem : classes.menuItem;
    const styles_import = pageName === 'import' ? classes.selectedMenuItem : classes.menuItem;
    const styles_users = pageName === 'users' ? classes.selectedMenuItem : classes.menuItem;
    const styles_support = pageName === 'support' ? classes.selectedMenuItem : classes.menuItem;
    const styles_settings = pageName === 'settings' ? classes.selectedMenuItem : classes.menuItem;

    let bookingPageName = 'Bookings';
    if (pageName === 'edit-booking') {
        bookingPageName = 'Edit Booking';
    } else if (pageName === 'view-booking') {
        bookingPageName = 'View Booking';
    }

    if (!isAdmin && !organizationId) {
        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar className={classes.toolbar}>
                        <NavLink to="/">
                            <span className={styles.logo}>ferris.</span>
                        </NavLink>
                        <NavLink to={link_support} className={classes.navLink}>
                            <Typography className={styles_support}>
                                Support
                            </Typography>
                        </NavLink>
                        <NavLink to={link_settings} className={classes.navLink}>
                            <Typography className={styles_settings}>
                                Settings
                            </Typography>
                        </NavLink>
                        <div style={{width: '200px'}}></div>
                        <div style={{width: '200px'}}></div>
                        <div style={{width: '200px'}}></div>
                        <div style={{width: '200px'}}></div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography variant="body1" className={classes.userInfo} color="secondary" noWrap>
                                <span className={classes.userName}>{name}</span>
                                <br/>
                                <span className={classes.orgName}>{organizationName}</span>
                            </Typography>
                            <Avatar alt={imageAlt} src={picture} className={classes.avatar}/>
                            <Button
                                variant="contained"
                                href="/logout/"
                                color="primary"
                                className={classes.signOutButton}
                            >
                                <Typography variant="body1" noWrap>
                                    Sign Out
                                </Typography>
                            </Button>
                        </div>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar className={classes.toolbar}>
                    <NavLink to="/">
                        <span className={styles.logo}>ferris.</span>
                    </NavLink>
                    <NavLink to={link_bookings} className={classes.navLink}>
                        <Typography className={styles_bookings}>{bookingPageName}</Typography>
                    </NavLink>
                    <NavLink to={link_add_booking} className={classes.navLink}>
                        <Typography className={styles_add_booking}>
                            Add Booking
                        </Typography>
                    </NavLink>
                    <NavLink to={link_import} className={classes.navLink}>
                        <Typography className={styles_import}>
                            Import
                        </Typography>
                    </NavLink>
                    <NavLink to={link_support} className={classes.navLink}>
                        <Typography className={styles_support}>
                            Support
                        </Typography>
                    </NavLink>
                    <NavLink to={link_settings} className={classes.navLink}>
                        <Typography className={styles_settings}>
                            Settings
                        </Typography>
                    </NavLink>
                    {
                        isAdmin && <NavLink to={link_users} className={classes.navLink}>
                            <Typography className={styles_users}>
                                Users
                            </Typography>
                        </NavLink>
                    }
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography variant="body1" className={classes.userInfo} color="secondary" noWrap>
                            <span className={classes.userName}>{name}</span>
                            <br/>
                            <span className={classes.orgName}>{organizationName}</span>
                        </Typography>
                        <Avatar alt={imageAlt} src={picture} className={classes.avatar}/>
                        <Button
                            variant="contained"
                            href="/logout/"
                            color="primary"
                            className={classes.signOutButton}
                        >
                            <Typography variant="body1" noWrap>
                                Sign Out
                            </Typography>
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default CustomAppBar;
