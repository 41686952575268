import React, {useEffect, useState,} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import {useHistory, useLocation, useParams} from "react-router-dom";
import AppBar from "../../components/Header";
import {Checkbox, FormControl, FormControlLabel, Tooltip,} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {ChevronLeft} from "@material-ui/icons";
import LinearProgress from "@material-ui/core/LinearProgress";
import {dateRenderer} from "../Bookings/utils/";

const useStyles = makeStyles({
    paper: {
        width: "100%",
        marginTop: 25,
    },
    container: {
        maxHeight: 800,
    },
    createDocument: {
        maxWidth: 200,
        marginLeft: 20,
    },
    textField: {
        margin: "0px 10px",
        minWidth: 200,
    },
    formControl: {
        margin: "0px 10px",
        width: '100%',
    },
    field: {
        width: "100%",
        margin: '0px 10px'
    },
    backButton: {
        width: '80px',
        height: '40px'
    },
    statusLabel: {
        width: '100%',
        fontSize: '14px',
        padding: '15px 5px',
        textAlign: 'right',
        lineHeight: '30px'
    },
    saveButton: {
        marginRight: '15px',
        marginLeft: '15px',
        float: 'right'
    },
    cancelButton: {
        float: 'right',
        textDecoration: 'none'
    },
    statusButton: {
        minWidth: '120px',
        marginTop: '10px',
        marginLeft: '10px',
        padding: '10px'
    }
});

export default function ViewBookingPage() {
    // const isMobile = useMediaQuery("(max-width:992px)");

    const history = useHistory();
    const classes = useStyles();
    const {id} = useParams();

    const location = useLocation();
    const [bookingsState, setBookingsState] = useState(null);

    useEffect(() => {
        if (location.state) {
            setBookingsState(location.state.bookingsState);
        }
    }, [location]);

    const [bookingId, setBookingId] = useState("");
    const [modifiedAt, setModifiedAt] = useState("");
    const [modifiedBy, setModifiedBy] = useState("");
    const [status, setStatus] = useState("");
    const [typeValue, setTypeValue] = useState("");
    const [clientRef, setClientRef] = useState("");
    const [vehicleRegistration, setVehicleRegistration] = useState("");
    const [vehicleModelValue, setVehicleModelValue] = useState("");

    const [collectionContactName, setCollectionContactName] = useState("");
    const [collectionContactMobile, setCollectionContactMobile] = useState("");
    const [collectionAddress, setCollectionAddress] = useState("");
    const [collectionPostCode, setCollectionPostCode] = useState("");
    const [collectionAdditionalInstructions, setCollectionAdditionalInstructions] = useState("");
    const [collectionFullInspection, setCollectionFullInspection] = useState(false);
    const [collectionNearCustomerValet, setCollectionNearCustomerValet] = useState(false);
    const [collectionProvideETA, setCollectionProvideETA] = useState(false);
    const [collectionRequiresFuel, setCollectionRequiresFuel] = useState(false);
    const [collectionIdCheck, setCollectionIdCheck] = useState(false);
    const [collectionDateValue, setCollectionDateValue] = useState(null);
    const [collectionTimeValue, setCollectionTimeValue] = useState(null);

    const [deliveryContactName, setDeliveryContactName] = useState("");
    const [deliveryContactMobile, setDeliveryContactMobile] = useState("");
    const [deliveryAddress, setDeliveryAddress] = useState("");
    const [deliveryPostCode, setDeliveryPostCode] = useState("");
    const [deliveryAdditionalInstructions, setDeliveryAdditionalInstructions] = useState("");
    const [deliveryFullInspection, setDeliveryFullInspection] = useState(false);
    const [deliveryNearCustomerValet, setDeliveryNearCustomerValet] = useState(false);
    const [deliveryProvideETA, setDeliveryProvideETA] = useState(false);
    const [deliveryRequiresFuel, setDeliveryRequiresFuel] = useState(false);
    const [deliveryIdCheck, setDeliveryIdCheck] = useState(false);
    const [deliveryDateValue, setDeliveryDateValue] = useState(null);
    const [deliveryTimeValue, setDeliveryTimeValue] = useState(null);

    const [returnContactName, setReturnContactName] = useState("");
    const [returnContactMobile, setReturnContactMobile] = useState("");
    const [returnAddress, setReturnAddress] = useState("");
    const [returnPostCode, setReturnPostCode] = useState("");
    const [returnAdditionalInstructions, setReturnAdditionalInstructions] = useState("");
    const [returnFullInspection, setReturnFullInspection] = useState(false);
    const [returnNearCustomerValet, setReturnNearCustomerValet] = useState(false);
    const [returnProvideETA, setReturnProvideETA] = useState(false);
    const [returnRequiresFuel, setReturnRequiresFuel] = useState(false);
    const [returnIdCheck, setReturnIdCheck] = useState(false);
    const [returnDateValue, setReturnDateValue] = useState(null);
    const [returnTimeValue, setReturnTimeValue] = useState(null);

    const [showCollectionTime, setShowCollectionTime] = useState(false);
    const [showDeliveryTime, setShowDeliveryTime] = useState(false);
    const [showReturnTime, setShowReturnTime] = useState(false);

    const [showReturnForm, setShowReturnForm] = useState(false);

    const fillBookingData = (booking) => {
        setBookingId(booking.booking_id);
        setModifiedAt(dateRenderer(booking.modified_at));
        setModifiedBy(booking.modified_name);
        setStatus(booking.status);
        setTypeValue(booking.type);
        setClientRef(booking.client_ref);
        setVehicleRegistration(booking.vehicle_registration);
        setVehicleModelValue(booking.vehicle_model);

        if (booking.collection) {
            let collection = booking.collection;
            setCollectionContactName(collection.contact_name);
            setCollectionContactMobile(collection.contact_mobile);
            setCollectionAddress(collection.address);
            setCollectionPostCode(collection.post_code);
            setCollectionAdditionalInstructions(collection.additional_instructions);
            setCollectionFullInspection(collection.full_inspection);
            setCollectionNearCustomerValet(collection.near_customer_valet);
            setCollectionProvideETA(collection.provide_eta);
            setCollectionRequiresFuel(collection.requires_fuel);
            setCollectionIdCheck(collection.id_check);
            setCollectionDateValue(collection.action_date);
            setCollectionTimeValue(collection.action_date);
        }

        if (booking.delivery) {
            let delivery = booking.delivery;
            setDeliveryContactName(delivery.contact_name);
            setDeliveryContactMobile(delivery.contact_mobile);
            setDeliveryAddress(delivery.address);
            setDeliveryPostCode(delivery.post_code);
            setDeliveryAdditionalInstructions(delivery.additional_instructions);
            setDeliveryFullInspection(delivery.full_inspection);
            setDeliveryNearCustomerValet(delivery.near_customer_valet);
            setDeliveryProvideETA(delivery.provide_eta);
            setDeliveryRequiresFuel(delivery.requires_fuel);
            setDeliveryIdCheck(delivery.id_check);
            setDeliveryDateValue(delivery.action_date);
            setDeliveryTimeValue(delivery.action_date);
        }

        if (booking['return']) {
            let returnObj = booking['return'];
            setReturnContactName(returnObj.contact_name);
            setReturnContactMobile(returnObj.contact_mobile);
            setReturnAddress(returnObj.address);
            setReturnPostCode(returnObj.post_code);
            setReturnAdditionalInstructions(returnObj.additional_instructions);
            setReturnFullInspection(returnObj.full_inspection);
            setReturnNearCustomerValet(returnObj.near_customer_valet);
            setReturnProvideETA(returnObj.provide_eta);
            setReturnRequiresFuel(returnObj.requires_fuel);
            setReturnIdCheck(returnObj.id_check);
            setReturnDateValue(returnObj.action_date);
            setReturnTimeValue(returnObj.action_date);
        }
    };

    useEffect(() => {
        axios.get("/api/booking/get/?booking-id=" + id).then((response) => {
            if (response.status === 200 && response.data) {
                let bookingData = response.data;
                if (bookingData) {
                    fillBookingData(bookingData);
                }
            }
        }).catch(() => {
        });
    }, [id]);

    useEffect(() => {
        setShowCollectionTime(false);
        setShowDeliveryTime(false);
        setShowReturnTime(false);

        setShowReturnForm(false);

        if (typeValue === 'Other') {
            setShowCollectionTime(true);
            setShowDeliveryTime(true);
            setShowReturnTime(true);
        } else if (typeValue === 'Delivery') {
            setShowDeliveryTime(true);
        } else if (typeValue === 'Collection') {
            setShowCollectionTime(true);
        } else if (typeValue === 'Update/Flip') {
            setShowDeliveryTime(true);
            setShowReturnForm(true);
        } else if (typeValue === 'Repair') {
            setShowCollectionTime(true);
        }

    }, [typeValue]);

    const handleGoBack = () => {
        history.push('/bookings/', {
            bookingsState: bookingsState
        });
    }

    if (!bookingId) {
        return (<>
            <AppBar pageName="view-booking"/>
            <div className="container-fluid d-flex flex-column mt-5">
                <Grid container spacing={1}>
                    <Grid item xs={12} spacing={1}>
                        <LinearProgress color="primary"/>
                    </Grid>
                </Grid>
            </div>
        </>);
    }

    return (<>
            <AppBar pageName="view-booking"/>
            <div className="container-fluid d-flex flex-column mt-5">
                <Paper className="d-flex p-3 justify-content-between flex-wrap align-items-baseline">
                    <Grid container spacing={1}>
                        <Grid container xs={4} spacing={1}>
                            <Tooltip title="Go Back">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    onClick={handleGoBack}
                                    className={classes.backButton}
                                >
                                    <ChevronLeft/>
                                    Back
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={8}>
                            <div className={classes.statusLabel}>
                                Last Edit by {modifiedBy} at {modifiedAt}
                            </div>
                        </Grid>
                        <Grid item xs={1}>
                            <div className={classes.statusLabel}>
                                Status:
                            </div>
                        </Grid>
                        <Grid item xs={8}>
                            <div>
                                <Button
                                    disabled={status !== "Booked"}
                                    className={classes.statusButton}
                                    color={status === "Booked" ? "primary" : undefined}
                                    size="large"
                                    variant="contained"
                                >Booked</Button>
                                <Button
                                    disabled={status !== "Cancelled"}
                                    className={classes.statusButton}
                                    color={status === "Cancelled" ? "primary" : undefined}
                                    size="large"
                                    variant="contained"
                                >Cancelled</Button>
                                <Button
                                    disabled={status !== "Active"}
                                    className={classes.statusButton}
                                    color={status === "Active" ? "primary" : undefined}
                                    size="large"
                                    variant="contained"
                                >Active</Button>
                                <Button
                                    disabled={true}
                                    className={classes.statusButton}
                                    size="large"
                                    variant="contained"
                                >Breakdown</Button>
                                <Button
                                    disabled={true}
                                    className={classes.statusButton}
                                    size="large"
                                    variant="contained"
                                >Completed</Button>
                                <Button
                                    disabled={true}
                                    className={classes.statusButton}
                                    size="large"
                                    variant="contained"
                                >Not Completed</Button>
                            </div>
                        </Grid>
                        <Grid container item xs={12} spacing={1}>&nbsp;</Grid>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item xs={2}>
                                <TextField
                                    label="Booking ID"
                                    type="text"
                                    variant="outlined"
                                    value={bookingId}
                                    contentEditable={false}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={2}>
                                <TextField
                                    label="Booking Type"
                                    type="text"
                                    variant="outlined"
                                    value={typeValue}
                                    contentEditable={false}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={2}>
                                <TextField
                                    label="Client Ref"
                                    type="text"
                                    variant="outlined"
                                    value={clientRef}
                                    contentEditable={false}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid container item xs={3}>
                                <TextField
                                    label="Vehicle Registration"
                                    type="text"
                                    variant="outlined"
                                    value={vehicleRegistration}
                                    className={classes.field}
                                    contentEditable={false}
                                />
                            </Grid>
                            <Grid container item xs={3}>
                                <TextField
                                    value={vehicleModelValue}
                                    label="Vehicle Model"
                                    type="text"
                                    variant="outlined"
                                    className={classes.field}
                                    contentEditable={false}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>&nbsp;</Grid>
                        <Grid container item xs={12}>
                            <span style={{fontSize: '16px'}}>Collected From</span>
                        </Grid>
                        <Grid container item xs={12}></Grid>
                        <Grid container item xs={5} spacing={2} justify="space-around">
                            <Grid container item xs={6} spacing={1}>
                                <TextField
                                    label="Contact Name"
                                    type="text"
                                    variant="outlined"
                                    value={collectionContactName}
                                    className={classes.field}
                                    contentEditable={false}
                                />
                            </Grid>
                            <Grid container item xs={6} spacing={1}>
                                <TextField
                                    label="Contact Mobile"
                                    type="text"
                                    variant="outlined"
                                    value={collectionContactMobile}
                                    className={classes.field}
                                    contentEditable={false}
                                />
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <TextField
                                    label="Address"
                                    type="text"
                                    variant="outlined"
                                    value={collectionAddress}
                                    className={classes.field}
                                    contentEditable={false}
                                />
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <TextField
                                    label="Post Code"
                                    type="text"
                                    variant="outlined"
                                    value={collectionPostCode}
                                    className={classes.field}
                                    contentEditable={false}
                                />
                            </Grid>
                            <Grid container item xs={11} justify="space-between">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        readOnly={true}
                                        disableToolbar
                                        variant="inline"
                                        inputVariant="outlined"
                                        format="EE do LLLL yyyy"
                                        margin="normal"
                                        label="Collection Date"
                                        value={collectionDateValue}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                    {
                                        showCollectionTime && <KeyboardTimePicker
                                            readOnly={true}
                                            margin="normal"
                                            ampm={false}
                                            clearable
                                            inputVariant="outlined"
                                            label="Collection Time"
                                            value={collectionTimeValue}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        />
                                    }
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <Grid container item xs={4} spacing={1}>
                            <TextField
                                multiline
                                rowsMax={12}
                                rows={12}
                                placeholder="Additional Instructions"
                                type="textarea"
                                variant="outlined"
                                value={collectionAdditionalInstructions}
                                className={classes.field}
                                contentEditable={false}
                            />
                        </Grid>
                        <Grid container item xs={3} spacing={1}>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={collectionFullInspection}
                                                readOnly={true}
                                            />
                                        }
                                        label="Full Inspection"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={collectionNearCustomerValet}
                                                readOnly={true}
                                            />
                                        }
                                        label="Near-Customer Valet"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={collectionProvideETA}
                                                readOnly={true}
                                            />
                                        }
                                        label="Driver to provide ETA"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={collectionRequiresFuel}
                                                readOnly={true}
                                            />
                                        }
                                        label="Requires Fuel"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={collectionIdCheck}
                                                readOnly={true}
                                            />
                                        }
                                        label="ID check"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12}>&nbsp;</Grid>
                    <Grid container item xs={12}>&nbsp;</Grid>
                    <div style={{width: '100%'}}>
                        <hr style={{color: ' #CFCFCF'}}/>
                    </div>
                    <Grid container item xs={12}>&nbsp;</Grid>

                    <Grid container spacing={1}>
                        <Grid container item xs={12}>
                            <span style={{fontSize: '16px'}}>Delivered To</span>
                        </Grid>
                        <Grid container item xs={12}></Grid>
                        <Grid container item xs={5} spacing={2} justify="space-around">
                            <Grid container item xs={6} spacing={1}>
                                <TextField
                                    label="Contact Name"
                                    type="text"
                                    variant="outlined"
                                    value={deliveryContactName}
                                    className={classes.field}
                                    contentEditable={false}
                                />
                            </Grid>
                            <Grid container item xs={6} spacing={1}>
                                <TextField
                                    label="Contact Mobile"
                                    type="text"
                                    variant="outlined"
                                    value={deliveryContactMobile}
                                    className={classes.field}
                                    contentEditable={false}
                                />
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <TextField
                                    label="Address"
                                    type="text"
                                    variant="outlined"
                                    value={deliveryAddress}
                                    className={classes.field}
                                    contentEditable={false}
                                />
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <TextField
                                    label="Post Code"
                                    type="text"
                                    variant="outlined"
                                    value={deliveryPostCode}
                                    className={classes.field}
                                    contentEditable={false}
                                />
                            </Grid>
                            <Grid container item xs={11} justify="space-between">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        readOnly={true}
                                        disableToolbar
                                        variant="inline"
                                        inputVariant="outlined"
                                        format="EE do LLLL yyyy"
                                        margin="normal"
                                        label="Delivery Date"
                                        value={deliveryDateValue}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                    {
                                        showDeliveryTime && <KeyboardTimePicker
                                            readOnly={true}
                                            margin="normal"
                                            ampm={false}
                                            clearable
                                            inputVariant="outlined"
                                            label="Delivery Time"
                                            value={deliveryTimeValue}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        />
                                    }
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <Grid container item xs={4} spacing={1}>
                            <TextField
                                multiline
                                rowsMax={12}
                                rows={12}
                                placeholder="Additional Instructions"
                                type="textarea"
                                variant="outlined"
                                value={deliveryAdditionalInstructions}
                                className={classes.field}
                                contentEditable={false}
                            />
                        </Grid>
                        <Grid container item xs={3} spacing={1}>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={deliveryFullInspection}
                                                readOnly={true}
                                            />
                                        }
                                        label="Full Inspection"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={deliveryNearCustomerValet}
                                                readOnly={true}
                                            />
                                        }
                                        label="Near-Customer Valet"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={deliveryProvideETA}
                                                readOnly={true}
                                            />
                                        }
                                        label="Driver to provide ETA"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={deliveryRequiresFuel}
                                                readOnly={true}
                                            />
                                        }
                                        label="Requires Fuel"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={deliveryIdCheck}
                                                readOnly={true}
                                            />
                                        }
                                        label="ID check"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        showReturnForm && <>
                            <Grid container item xs={12}>&nbsp;</Grid>
                            <Grid container item xs={12}>&nbsp;</Grid>
                            <div style={{width: '100%'}}>
                                <hr style={{color: ' #CFCFCF'}}/>
                            </div>
                            <Grid container item xs={12}>&nbsp;</Grid>

                            <Grid container spacing={1}>
                                <Grid container item xs={12}>
                                    <span style={{fontSize: '16px'}}>Return Vehicle To</span>
                                </Grid>
                                <Grid container item xs={12}></Grid>
                                <Grid container item xs={5} spacing={2} justify="space-around">
                                    <Grid container item xs={6} spacing={1}>
                                        <TextField
                                            label="Contact Name"
                                            type="text"
                                            variant="outlined"
                                            value={returnContactName}
                                            className={classes.field}
                                            contentEditable={false}
                                        />
                                    </Grid>
                                    <Grid container item xs={6} spacing={1}>
                                        <TextField
                                            label="Contact Mobile"
                                            type="text"
                                            variant="outlined"
                                            value={returnContactMobile}
                                            className={classes.field}
                                            contentEditable={false}
                                        />
                                    </Grid>
                                    <Grid container item xs={12} spacing={1}>
                                        <TextField
                                            label="Address"
                                            type="text"
                                            variant="outlined"
                                            value={returnAddress}
                                            className={classes.field}
                                            contentEditable={false}
                                        />
                                    </Grid>
                                    <Grid container item xs={12} spacing={1}>
                                        <TextField
                                            label="Post Code"
                                            type="text"
                                            variant="outlined"
                                            value={returnPostCode}
                                            className={classes.field}
                                            contentEditable={false}
                                        />
                                    </Grid>
                                    <Grid container item xs={11} justify="space-between">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                readOnly={true}
                                                disableToolbar
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="EE do LLLL yyyy"
                                                margin="normal"
                                                label="Return Date"
                                                value={returnDateValue}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                            {
                                                showReturnTime && <KeyboardTimePicker
                                                    readOnly={true}
                                                    margin="normal"
                                                    ampm={false}
                                                    clearable
                                                    inputVariant="outlined"
                                                    label="Return Time"
                                                    value={returnTimeValue}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                />
                                            }
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={4} spacing={1}>
                                    <TextField
                                        multiline
                                        rowsMax={12}
                                        rows={12}
                                        placeholder="Additional Instructions"
                                        type="textarea"
                                        variant="outlined"
                                        value={returnAdditionalInstructions}
                                        className={classes.field}
                                        contentEditable={false}
                                    />
                                </Grid>
                                <Grid container item xs={3} spacing={1}>
                                    <Grid container item xs={12} spacing={1}>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormControlLabel
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        color="secondary"
                                                        checked={returnFullInspection}
                                                        readOnly={true}
                                                    />
                                                }
                                                label="Full Inspection"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={12} spacing={1}>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormControlLabel
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        color="secondary"
                                                        checked={returnNearCustomerValet}
                                                        readOnly={true}
                                                    />
                                                }
                                                label="Near-Customer Valet"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={12} spacing={1}>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormControlLabel
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        color="secondary"
                                                        checked={returnProvideETA}
                                                        readOnly={true}
                                                    />
                                                }
                                                label="Driver to provide ETA"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={12} spacing={1}>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormControlLabel
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        color="secondary"
                                                        checked={returnRequiresFuel}
                                                        readOnly={true}
                                                    />
                                                }
                                                label="Requires Fuel"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={12} spacing={1}>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormControlLabel
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        color="secondary"
                                                        checked={returnIdCheck}
                                                        readOnly={true}
                                                    />
                                                }
                                                label="ID check"
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    }
                </Paper>
            </div>
        </>
    );
}
