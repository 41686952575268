import React from "react";
import "./styles/main.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Bookings from "./containers/Bookings";
import AddBooking from "./containers/AddBooking";
import EditBooking from "./containers/EditBooking";
import ViewBooking from "./containers/ViewBooking";
import Import from "./containers/Import";
import Users from "./containers/Users";
import Support from "./containers/Support";
import Settings from "./containers/Settings";
import { UserContextProvider } from "./contexts/user-profile";

function App() {
  return (
    <div className="App">
      <UserContextProvider>
        <Router>
          <Switch>
            <Route exact path="/" component={Bookings} />
            <Route exact path="/bookings" component={Bookings} />
            <Route exact path="/booking/add" component={AddBooking} />
            <Route path="/booking/edit/:id" component={EditBooking} />
            <Route path="/booking/view/:id" component={ViewBooking} />
            <Route exact path="/import" component={Import} />
            <Route exact path="/support" component={Support} />
            <Route exact path="/settings" component={Settings} />
            <Route exact path="/users" component={Users} />
          </Switch>
        </Router>
        <ToastContainer autoClose={1000} />
      </UserContextProvider>
    </div>
  );
}

export default App;
