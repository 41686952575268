import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";

const useStyles = makeStyles({
    dialog: {
        height: "400px",
        width: 500,
    },
    table: {
        width: '100%'
    },
    tableRow: {
        cursor: 'pointer',
    },
    columnName: {
        fontWeight: 'bold',
        fontSize: '14px'
    },
});

export default function AddUserDialog({users, onSave, onCancel}) {
    const classes = useStyles();

    const [selectedRow, setSelectedRow] = useState();

    const handleSave = () => {
        onSave(selectedRow);
    };
    const handleCancel = () => {
        onCancel();
    };

    return (
        <Dialog
            open={true}
            fullWidth={true}
        >
            <DialogTitle>Add User</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Table className={classes.table}>
                        <TableBody>
                            {users.map((user) => (
                                <TableRow
                                    className={classes.tableRow}
                                    key={user.email}
                                    hover={true}
                                    selected={selectedRow === user}
                                    onClick={() => {
                                        setSelectedRow(user);
                                    }}
                                >
                                    <TableCell align="left">{user.email}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={!selectedRow}
                    color="primary"
                    variant="contained"
                    onClick={handleSave}
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
